import { actionType } from 'appRedux/actionType';

export function toggleDrawer(payload) {
  return {
    type: actionType.TOGGLE_DRAWER_METHOD,
    payload: payload
  }
}




