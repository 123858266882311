import { put } from "redux-saga/effects";
import {
  API_PRODUCT_VAR_IMG,
  API_DESC_PRODUCT,
  API_IMPORT_PRODUCT,
  API_CATEGORY_PRIM,
  API_PRODUCT,
  API_PRODUCT_V,
} from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";
import { takeLatest, takeEvery } from "redux-saga/effects";

// ----- Get Product Watcher -----
export function* watchGetProductSaga() {
  yield takeLatest(actionType.GET_PRODUCT_METHOD, getProductSaga);
}

export function* watchGetProductDetailSaga() {
  yield takeLatest(actionType.GET_PRODUCT_DETAIL_METHOD, getProductDetailSaga);
}

export function* watchGetCategorySaga() {
  yield takeLatest(actionType.GET_CATEGORY_METHOD, getCategorySaga);
}

export function* watchImportProductSaga() {
  yield takeEvery(actionType.IMPORT_PRODUCT_METHOD, importProductSaga);
}

export function* watchPostProductSaga() {
  yield takeEvery(actionType.POST_PRODUCT_METHOD, postProductSaga);
}

export function* watchDeleteProductSaga() {
  yield takeEvery(actionType.DEL_PRODUCT_METHOD, deleteProductSaga);
}

export function* watchUpdateProductSaga() {
  yield takeEvery(actionType.UPDATE_PRODUCT_METHOD, updateProductSaga);
}

export function* watchUpdateVariantSaga() {
  yield takeEvery(actionType.UPDATE_VARIANT_METHOD, updateVariantSaga);
}

export function* watchDeleteVariantSaga() {
  yield takeEvery(actionType.DEL_VARIANT_METHOD, deleteVariantSaga);
}

export function* watchCreateVariantSaga() {
  yield takeEvery(actionType.CREATE_VARIANT_METHOD, createVariantSaga);
}

export function* watchCreateVariantImageSaga() {
  yield takeEvery(actionType.CREATE_IMG_METHOD, createVariantImageSaga);
}

export function* watchDeleteVariantImageSaga() {
  yield takeEvery(actionType.DEL_IMG_METHOD, deleteVariantImageSaga);
}

// ----- Saga -----
function* getProductSaga(action) {
  try {
    let url = {
      type: API_DESC_PRODUCT,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_PRODUCT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_PRODUCT_FAILURE, payload: error });
  }
}

function* getProductDetailSaga(action) {
  try {
    let url = {
      type: API_PRODUCT,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_PRODUCT_DETAIL_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_PRODUCT_DETAIL_FAILURE, payload: error });
  }
}

function* getCategorySaga(action) {
  try {
    let url = {
      type: API_CATEGORY_PRIM,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_CATEGORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_CATEGORY_FAILURE, payload: error });
  }
}

function* postProductSaga(action) {
  try {
    let url = {
      type: API_PRODUCT,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.POST_PRODUCT_SUCCESS, payload: res });
    yield put({ type: actionType.POST_PRODUCT_CLEAR, payload: {} });
  } catch (error) {
    yield put({ type: actionType.POST_PRODUCT_FAILURE, payload: error });
  }
}

function* importProductSaga(action) {
  try {
    let url = {
      type: API_IMPORT_PRODUCT,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.IMPORT_PRODUCT_SUCCESS, payload: res });
    yield put({ type: actionType.IMPORT_PRODUCT_CLEAR, payload: {} });
  } catch (error) {
    yield put({ type: actionType.IMPORT_PRODUCT_FAILURE, payload: error });
  }
}

function* deleteProductSaga(action) {
  try {
    let url = {
      type: API_PRODUCT,
      payload: action.payload,
      apiType: "DELETE",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.DEL_PRODUCT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.DEL_PRODUCT_FAILURE, payload: error });
  }
}

function* updateProductSaga(action) {
  try {
    let url = {
      type: API_PRODUCT,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.UPDATE_PRODUCT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.UPDATE_PRODUCT_FAILURE, payload: error });
  }
}

function* createVariantSaga(action) {
  try {
    let url = {
      type: API_PRODUCT_V,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.CREATE_VARIANT_SUCCESS, payload: res });
    yield put({ type: actionType.CREATE_VARIANT_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.CREATE_VARIANT_FAILURE, payload: error });
  }
}

function* updateVariantSaga(action) {
  try {
    let url = {
      type: API_PRODUCT_V,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.UPDATE_VARIANT_SUCCESS, payload: res });
    yield put({ type: actionType.UPDATE_VARIANT_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.UPDATE_VARIANT_FAILURE, payload: error });
  }
}

function* deleteVariantSaga(action) {
  try {
    let url = {
      type: API_PRODUCT_V,
      payload: action.payload,
      apiType: "DELETE",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.DEL_VARIANT_SUCCESS, payload: res });
    yield put({ type: actionType.DEL_VARIANT_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.DEL_VARIANT_FAILURE, payload: error });
  }
}

function* createVariantImageSaga(action) {
  try {
    let url = {
      type: API_PRODUCT_VAR_IMG,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.CREATE_IMG_SUCCESS, payload: res });
    yield put({ type: actionType.CREATE_IMG_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.CREATE_IMG_FAILURE, payload: error });
  }
}

function* deleteVariantImageSaga(action) {
  try {
    let url = {
      type: API_PRODUCT_VAR_IMG,
      payload: action.payload,
      apiType: "DELETE",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.DEL_IMG_SUCCESS, payload: res });
    yield put({ type: actionType.DEL_IMG_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.DEL_IMG_FAILURE, payload: error });
  }
}
