import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  earningLoading: false,
  earningError: "",
  earningRes: "",
};

export default function earningReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.GET_EARNING_METHOD:
      return { ...state, earningLoading: true, earningError: "" };
    case actionType.GET_EARNING_SUCCESS:
      return {
        ...state,
        earningLoading: false,
        earningError: "",
        earningRes: action.payload.data,
      };
    case actionType.GET_EARNING_FAILURE:
      let tearningError = action.payload;
      return {
        ...state,
        earningLoading: false,
        earningError: tearningError,
      };
    case actionType.GET_EARNING_CLEAR:
      return {
        ...state,
        earningLoading: false,
        earningError: "",
        earningRes: "",
      };

    default:
      return state;
  }
}
