import { actionType } from "appRedux/actionType";

export function getSellerProfile(payload) {
  return {
    type: actionType.GET_PROFILE_METHOD,
    payload: payload,
  };
}

export function updateSellerProfile(payload) {
  return {
    type: actionType.UPDATE_PROFILE_METHOD,
    payload: payload,
  };
}

// export function sellerLogin(payload) {
//   return {
//     type: actionType.SELLER_LOGIN_METHOD,
//     payload: payload
//   }
// }
