import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMoreIcon } from "common/icons";
import React from "react";

const SAccordion = ({ summary, details, disabled }) => {
  return (
    <Accordion
      disabled={disabled ? disabled : false}
      sx={{ boxShadow: "0px 3px 6px rgba(0,97,86, .15)", borderRadius: "15px" }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{ height: "80px", borderRadius: "15px" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ fontFamily: "Medium65", fontSize: "1.3rem" }}>
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export default SAccordion;
