import { actionType } from "../actionType";

export default function authReducer(
  state = {
    isUserLoggedIn: undefined,
  },
  action
) {
  switch (action.type) {
    case actionType.SELLER_LOGIN_METHOD:
      return { ...state, isUserLoggedIn: true };

    /////////////////////////////////////////////////////////////////////
    case actionType.SELLER_LOGOUT_METHOD:
      return { ...state, isUserLoggedIn: false };

    default:
      return state;
  }
}
