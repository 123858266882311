import React from 'react';
import SModal from 'common/material/panel/SModal';
import { Container, Box, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  px: 4,
  py: 2,
  border: 'none',
  width: '80vw'
};

const Terms = ({ open, handleClose }) => {
  return (
    <SModal
      open={open}
      handleClose={handleClose}
    >
      <Container sx={style}>
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.75rem' }}>
            Terms And Conditions
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              background: '#F5F5F5',
              right: 0,
              top: 0
            }}
            onClick={handleClose}
          >
            <Close fontSize='small' color="#000" />
          </IconButton>
        </Box>
        <Box sx={{ my: 3, maxHeight: '70vh', overflowY: 'auto' }}>
          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.3rem', mb: 2 }}>
            Welcome to SPACIUM
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            1. INTRODUCTION
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            1.1
            These Terms and Conditions set out the terms in which products and services are purchased on https://www.spacium.ae/ and its related sub-domains, sites, and mobile application (together defined as the “Site” or “Platform”).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            1.2
            Spacium Furnitures – Sole Proprietorship LLC, maintains the https://www.spacium.ae/ website and its related sub-domains, sties, and mobile application and is a company duly established pursuant to the laws of the United Arab Emirates with Abu Dhabi DED Trade License No. CN-3650905, having its registered office at Office No. 622, 6th Floor, Emirates Dermatology Building, Al Nahyan, Abu Dhabi, UAE (herein referred to as “Spacium”, “we”, “our” or “us”).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            1.3
            Spacium is an online e-shop platform accessed via the Site, showcasing furniture products and accessories from various furniture stores (the “Products”) under one platform for any party accessing the Site (the “Users”) to browse and order products, compare prices, hire freelance interior designers and earn reward points. Spacium is merely a facilitator of the purchase of Products and is not to be deemed as the supplier, manufacturer or delivery/shipping provider for any of the Products or Orders.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            1.4
            The Site will collect data that permits someone to identify or contact you, such as, but not limited to, your full name, address, telephone number, email address, referral source, product interest/preference as well as any information is associated with or linked to you (the “Personal Data”). All data collected, gathered and/or retained is under compliance of the privacy policy of the Site and the operating region’s data protection laws.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            1.5
            United Arab Emirates (“UAE”) is our Country of Domicile. Spacium controls this Site from the UAE. Spacium makes no representation that this Site is appropriate for use in other locations. If you use this Site from other locations, you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any UAE export laws and regulations.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            2. TERMS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.1
            These Terms of Conditions and Use set out the terms of use of the Site for the purpose of the Users’ purchase of the Products (the “Terms”).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.2
            These Terms will form a legally binding agreement between “you” (as an end user of the Site) and Spacium. If you do not agree to the Terms, immediately refrain from using this Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.3
            By accessing, browsing, using, registering with, or placing an order on the Site, you confirm that you have read, understood and agree to these Terms.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.4
            These Terms are subject to change and/or revision without notice, as per the updated and effective laws and regulations at any given time. Spacium encourages you to review the Terms regularly for any changes. Your continued use of the Platform shall constitute your express consent and agreement to be bound by the then current Terms. If you disagree with any of the Terms or any changes to the Terms, you must immediately refrain from using the Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.5
            Please read these Terms carefully before you submit your order via the Site. By placing an order on the Site, you are agreeing to be bound by these Terms with immediate effect.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.6
            The Terms are in line with the relevant applicable UAE laws and regulations, and are not intended to affect any rights available to you as a consumer under the UAE Laws.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            2.7
            You are granted a limited, non-exclusive, revocable and non-transferable license to utilize and access the Site pursuant to the requirements and restrictions of these Terms.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            3. ACCOUNTS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.1
            To purchase any products on the Site, you may either register and create an account secured by a password (“Account”) or submit your order without creating the Account as a guest user. When you register, you consent to providing us with information about yourself.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.2
            To create an Account, you must be at least 18 years of age and are required to provide true, accurate, complete and current information (including Name, Date of Birth, Email Address, and other details) as requested during the Account creation. By continuing to use the Site, you consent and agree that you are (i) 18 years of age or have reached the age of majority in the jurisdiction in which you reside and (ii) are permitted to use the Site under any applicable Law. If you are below 18 years of age, you shall not register as a User of the Site and shall not transact on or use the Site. However, you may use this Site only under the supervision of a parent or legal guardian who agrees to be bound by these Terms and you are responsible for ensuring such use to be permitted under UAE Law and any other applicable law.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.3
            By registering yourself on the Site, you warrant that the details you provide to Spacium are correct and complete and you agree that you will inform us immediately of any changes to those details (e.g. change of email or postal address). You can access and update your details using the "My Account" area of the Platform. You authorise us to verify and use any information provided to us during registration and account creation (including any updated information) as well as request any documentation to verify the submitted details. Spacium will not be responsible or held liable for any details submitted by you on this Platform (“User-Generated Content”).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.4
            You are responsible for maintaining the confidentiality of your Account username and password and for preventing unauthorised access by third parties to your profile. You agree to accept responsibility for all activities that occur under your Account. Please take all necessary steps to ensure that the username and password are kept confidential and secure. Please inform Spacium immediately if you have any reason to believe that your username and/or password has become known to anyone else, or are being, or are likely to be used in an unauthorised manner. Spacium will never request your Account username or password through electronic messages. Spacium will not be liable for any loss or damage which may arise as a result of any failure by you in protecting your password or Account.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.5
            You will not be charged by Spacium for registering an Account or browsing the Site. You will only be responsible for making a payment for any purchases through the Site (including any tax on the purchase price, if any).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            3.6
            Spacium reserves the right to refuse access to the Site, terminate Accounts, remove or edit User-Generated Content, or cancel orders at our discretion. If Spacium cancels an order, it will be without charge to you.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            4. PRIVACY POLICY
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The personal information you provide in the course of using the Site will be used in accordance with our Privacy Policy. When providing certain information on the Site, you confirm that you have read, understood and agree to the Privacy Policy. By using the Site, you consent and agree to the Privacy Policy (the “Privacy Policy”). If you do not agree to the Privacy Policy, then you must immediately refrain from using the Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            5. YOUR USE OF THE PLATFORM
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.1
            The Site provides information and material about Spacium, including the services we provide, our operations and our contact information. The Site and the content available are only created for informational purposes. This Site does not provide legal or business advice. It does not provide any kind of advice and you should not depend on it for advice. You act at your own risk in relation to the contents of the Site. Spacium takes no responsibility for any information provided by our employees, directors, or affiliates. Any action taken by you is fully your decision, and you absolve Spacium of any liability for any outcome that may occur due to your actions on inactions or decisions you take in reliance to the Site or material therein.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.2
            You have consented and agreed that all transactions and data on this Site will be saved, which includes all transactions transacted and traded through the Site, third party websites, payment gateways and any cloud platforms in any jurisdictions whatsoever.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.3
            You may only use the Platform for lawful purposes and you may not use it in a way that infringes the rights of anyone else or that restricts or inhibits anyone else's enjoyment of the Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.4
            You may use the Platform for personal use only unless otherwise specifically authorised by Spacium to do so in writing. The User creating the Account is the sole authorized user of the Account, any use of the Account by a third party shall constitute an unauthorized use of the Account which Spacium shall not be responsible or held liable for.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.5
            This Site may not be used by you for any commercial purposes such as to conduct sales of merchandise or services of any kind. Spacium will investigate and take appropriate legal action against anyone who violates this provision, including without limitation, removing the offending communication from the Site and barring such violators from use of the Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.6
            You shall not duplicate, download, publish, modify or otherwise distribute the material on this Site for any purpose other than for your personal use. Unless it is required by the applicable laws or you have our written permission, you may not reverse engineer or attempt to extract the source code of the Platform. You may only access the Platform through the interfaces that we provide for your personal use only and may not "scrape" the Platform through automated means i.e. extract large amounts of information from the Platform or "frame" any part of the Platform i.e. transmit digital data from the Platform, and you may not interfere or attempt to disrupt the Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.7
            Except where otherwise specified on this Platform, the materials on this Platform are displayed solely for the purposes of promoting products available in the UAE. However, Spacium may accept orders for delivery to locations outside of the UAE subject to customs, legal, regulatory and certain practical restrictions and requirements. Users who choose to access this Platform from locations outside the UAE or place orders for delivery to locations outside the UAE are responsible for compliance with applicable local laws and any related costs and/or expenses.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.8
            You may not attempt to gain unauthorised access to any portion or feature of the Platform, or any other systems or networks connected to the Platform, or to any of the services offered on or through the Platform, by hacking, password "mining" i.e. recovering passwords stored on the Platform or any other illegitimate means.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            5.9
            We reserve the right to restrict or terminate your access to, or use of, the Platform, Site or any feature or part of it at any time if you breach these Terms or for any other reason whatsoever without notice. Termination or limitation of your access or use will not waive or affect any other right or relief to which we may be entitled at law. You agree that monetary damages may not provide a sufficient remedy to Spacium for violations of these Terms and you consent to injunctive or other equitable relief for such violations. Spacium may release user information about you if required by law or subpoena.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            6. MATERIALS AVAILABLE ON SITE
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            6.1
            We and our suppliers and/or licensors may make available various merchandise information, product descriptions, reviews, and other information, data, content, and materials on the Site ("Materials"). The Materials are for educational and informational purposes only, and may contain errors, inaccuracies or omissions that may relate to the product or Platform. Before you act in reliance of any Materials, you should confirm any facts that are important to your decision with Us or the supplier. We make no warranty as to the reliability, accuracy, timeliness, usefulness, or completeness of any Materials. If you find an error or notice incorrect information on the Platform, please let us know by contacting us at admin@spacium.ae or call +971 504000604
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            6.2
            While Spacium takes reasonable steps to ensure that Product information on our site is correct, Product images displayed on the Site may slightly differ from the actual Product i.e. there may be differences in the colour of products. We recommend you to not rely solely on the information presented on our Site and to always refer to the Product description for more detailed descriptions of the Products. Spacium shall not be responsible or held liable for any errors, inaccuracies or omissions in the Product information.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            6.3
            You are only allowed to access and use the Site and the Materials for your personal purposes, and you must not, in addition to the prohibitions as set forth above:
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <ul >
              <li>Utilize the Site or Materials for any unlawful purpose;</li>
              <li>Utilize the Site to solicit others to perform or participate in any unlawful acts;</li>
              <li>Utilize the Site to violate any international, federal, or local laws and regulations;</li>
              <li>Copy, utilize, circulate, change, assign, distribute or in any manner use the Materials, except as otherwise expressly allowed in these Terms;</li>
              <li>Use any device or software that interfere with the Site;</li>
              <li>Sell, circulate m recreate or in any way use the Materials for any commercial purposes;• Access information not intended for you by attempting to gain unauthorized access;</li>
              <li>Utilize the Site or Materials to circulate, distribute, or sell in a manner that will violate the copyright or other intellectual property laws or infringe upon the privacy or rights of Spacium or others;</li>
              <li>Harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
              <li>Submit false or misleading information, use the Materials to use an identity that is fictious or misrepresent a connection with Spacium or another person;</li>
              <li>Upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Site;</li>
              <li>Collect or track the personal information of others; and</li>
              <li>Interfere with or circumvent the security features of the Site or in any manner attempt to interfere with the working of the Site.</li>
            </ul>
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            7. USER WARRANTIES
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            You, as the User warrant that:
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            7.1
            You have the authority and capacity to use the Site and to enter into all of the agreements to continue with the Site services;
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            7.2
            All information and documentation provided by you is true and correct; and
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            7.3
            You are responsible for paying Spacium consideration for the Products.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            8. USER CONDUCT AND USER GENERATED CONTENT
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            8.1
            The Platform may at times provide opportunities for users to submit comments, reviews, suggestions, questions or other information ("User-Generated Content") on or through the Platform. You warrant that any such User-Generated Content submitted by you to the Platform is original (and does not infringe the intellectual property rights of others).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            8.2
            You hereby grant Spacium a perpetual, irrevocable, non-exclusive, royalty-free, transferable right and licence to use such User-Generated Content submitted for any purpose without restriction including without limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create derivative works from and/or sell and/or distribute such User-Generated Content and/or incorporate such User-Generated Content into any form, medium or technology throughout the world, without any further recourse to you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            8.3
            You waive any moral rights in such User-Generated Content and agree that you will not submit any content on the Platform that is contrary to public policy, morality, defamatory, inflammatory, that infringes intellectual property rights, or that may otherwise breach common decency or be contrary to any applicable laws. We have the right to monitor and edit or remove any activity or User-Generated Content at our sole discretion, and assume no responsibility or liability for any User-Generated Content posted by you or any third party.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            9. CHANGE OF SITE AND CESSATION OF OPERATIONS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            9.1
            We reserve the right to change or correct any errors or omissions on the Platform, and to alter, limit or discontinue the provision of any of the services provided through the Platform at any time without prior notice to you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <ul style={{ listStyleType: 'circle' }}>
              <li>The Platform may contain links to other websites as a convenience to you and other users of the Site. We make no representations or warranties as to the content, privacy practices and other practices of any websites operated by a third party and Spacium does not assume responsibility for the content or practices of such websites. The use of third-party websites will be at your own risk and it is your sole responsibility to review the terms, privacy policies and other policies for said websites.</li>
              <li>If you use any material or visit any linked website, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive material. Spacium does not endorse and is not responsible for the use of, or the content of, any third-party websites. Spacium is not responsible for any loss or damage caused directly or indirectly by using such third-party websites.</li>
              <li>The Site may contain advertisements and/or sponsorships. The advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. Spacium is not responsible for the acts or omissions of any advertiser or sponsor.</li>
            </ul>
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            10. INTELLECTUAL PROPERTY
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            10.1
            Unless otherwise expressly stated, all contents of the Platform are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by us or one of our affiliates or by third parties who have licensed their materials to us and are protected by the applicable laws.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            10.2
            We, together with our suppliers and/or licensors expressly reserve all intellectual property rights in all programs, products, product names, service names, logotype set apart in form, text or otherwise from surrounding text (i.e. all capital letters) processes, technology, content (that is graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code, images, advertisements, layout), including but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such content) and other materials, which appear on the Platform (“Intellectual Property Rights”). Access to the Platform does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party’s Intellectual Property Rights. Any use of the Platform or its contents, including copying or storing it or them in whole or in part, other than for identifying the Products or services as belonging to Spacium or its suppliers/licensors is prohibited without our express written permission.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            11. DISCLAIMER OF WARRANTY
          </Typography>
          The Platform is provided on an “as is” and “as available” basis. Spacium, its parents, subsidiaries, affiliates, and their respective suppliers, and any of their owners, officers, directors, managers, members, agents, and employees, expressly disclaim (to the fullest extent permitted by applicable law) any and all express, implied, and statutory warranties, including, without limitation, any warranties of merchantability, fitness for a particular purpose, freedom from computer, mobile or internet device virus, title, and non-infringement of proprietary rights. Spacium makes no representations or warranties of any kind, express or implied, as to the operation of the Site, the services or the information, content, materials, or Products included on this Site. This disclaimer does not apply to any Product warranty offered by the manufacturer or supplier of the product.

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            12. COMPLIANCE WITH TAX OBLIGATIONS & REGULATIONS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            12.1
            You are solely responsible to determine what, if any, taxes are applicable to your transactions on this Site in your particular jurisdictions. Spacium and the Site are not responsible for determining the taxes that apply to the transactions on this Site in your jurisdiction.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            12.2
            Spacium operates under Civil Law and UAE jurisdiction and therefore is compliant to the GDPR and UAE Law. However, you are solely responsible to determine what, if any regulations, apply to your transactions in your particular jurisdictions. Spacium and the Website are not responsible for determining the laws that apply to the transactions on this Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            13. LIMITATION OF LIABILITY
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            13.1
            To the maximum extent permissible by applicable law, Spacium, its parents, subsidiaries, affiliates, and their respective suppliers, and any of their owners, officers, directors, managers, members, agents, and employees:
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <ul>
              <li>Shall not be liable for any direct, indirect, punitive, incidental, special, or consequential damages or loss of any kind arising out of, or related in any way to your use or inability to use the Platform and its content, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether or not Spacium is advised of the possibility of such damages; and</li>
              <li>exclude any and all liability for damages caused by, any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any communications due to technical problems or traffic congestion on the Internet or on the Platform or combination thereof, including any injury or damage to any users or to any person’s computer related to or resulting from use of the Platform.</li>
              <li>In no event will Spacium be liable for any damages in excess of the amount paid by you towards the price of the product (which where applicable shall be refunded by Spacium on behalf of the supplier) in respect to which the claim arose. You hereby release us from any and all obligations, liabilities and claims in excess of this limitation.</li>
            </ul>
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            14. INDEMNITY
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            You agree to hold harmless and indemnify Spacium, its parents, subsidiaries, affiliates, and their respective suppliers (including, without limitation, Authorised Suppliers), and any of their owners, officers, directors, managers, members, agents, and employees, from and against any claims, liabilities, suits, judgments, litigation costs, causes of action, demands, recoveries, losses, damages (actual and consequential), fines, penalties, and attorneys’ fees or other costs or expenses of any kind or nature arising from or in any way related to your use of the Platform, including, but not limited to those brought by you or on your behalf in excess of the liability described above, or by third parties as a result of: Your breach of any part of these Terms; your violation of any applicable law, the rights of a third party, or your use of the Platform; any allegation that any materials/content/information you submit to us or transmit to the Spacium Platform infringe or otherwise violate the copyright, patent, trademark, trade secret, or other intellectual property or other rights of any third party.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            15. ENTIRE UNDERSTANDING
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            These Terms, as supplemented by any additional documents referred to in them and any policies or rules posted on the Platform (including any amendments made from time to time as contemplated by these Terms) supersedes all prior terms, agreements, communications, oral or written and constitutes the entire understanding and agreement between you and Spacium with respect to the use of the Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            16. TERMINATION OF SERVICE
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Spacium reserves the right, at its sole discretion, to control, limit, terminate, suspend or conclude your access to any part of the Site at any point in time without reason, previous notice or liability. Spacium may change, suspend or shut down any part of the Site at any time without prior notice or liability to you or anyone else. Spacium has the absolute right to disable your account or put it on hold/review if the customer is not complying with the rules. Regardless of such termination or suspension, you will continue to be subject to these Terms.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            17. GENERAL TERMS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Severability:
            </Typography>
            If any provision of these Terms is found to be invalid, illegal or unenforceable at any point, the invalid, illegal or unenforceable provisions will be severed and the remaining provisions and obligations shall remain valid and enforceable.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              No Waiver:
            </Typography>
            Any failure by us to enforce and exercise any right, power or privilege under these Terms or other policies will not be construed as a waiver of any subsequent or further exercise of that right, power or privilege or the exercise of any other right, power or privilege.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Further Assurance:
            </Typography>
            Spacium has the absolute right to disable your account or put it on hold/review if the customer is not complying with the rules.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Assignment and third party rights:
            </Typography>
            You may not assign or sub-contract any of your rights or obligations under these Terms and Conditions to any third party unless we agree in writing. We may assign, transfer or sub-contract any of our rights or obligations under these Terms to any third party at our discretion.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Amendments:
            </Typography>
            Any subsequent changes, additions or otherwise modifications of any of these Terms or privacy policy or other policies shall be made at any time without prior notice to you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Relationship:
            </Typography>
            None of the provisions in these Terms shall be construed to constitute any User to be the agent, partner, distributor, legal representative, or employee of Spacium for any purpose whatsoever and vice versa. Neither User nor Spacium shall have the power or authority to bind the other except as specifically set out in these Terms.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Supplemental Terms:
            </Typography>
            Certain services offered on the Spacium Platform may require you to enter into a separate agreement and/or be subject to additional terms.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Headings:
            </Typography>
            Headings &amp; section titles in this Agreement are for convenience and do not define, limit, or extend any provision of this Agreement.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            18. GOVERNING LAW, JURISDICTION, DISPUTES AND LANGUAGE
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The Terms shall be governed and construed in accordance with the Laws and Regulations of the emirate of Abu Dhabi and United Arab Emirates. ;If you have a dispute or concern with Spacium, please contact us at admin@spacium.ae or call +971 504000604. We shall endeavour to resolve all disputes or differences in relation to these Terms informally and amicably. Any dispute regarding these Terms which have not been resolved amicably shall be subject to the exclusive jurisdiction of the Courts of Abu Dhabi. ;All contracts for the purchase and sale of Products from the Platform shall be concluded in the language of these Terms. These Terms may be published in English and Arabic. If there is any inconsistency between the English text and the Arabic text, the English text shall prevail.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            19. ORDER ACCEPTANCE
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.1
            Supplier. Each product and/or service purchased from the Platform shall be delivered by the local or international supplier that is specified on the Site via self-shipment or third-party logistics (“3PL”). Spacium will not be responsible or held liable for any damages in the product or delays in the delivery of the product. Spacium shall merely facilitate the sale and purchase of the products between the supplier and customer.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.2
            Order Acceptance. Your order will be deemed to be accepted only once you receive a confirmation in writing (e.g. by email or mobile messaging). If we are unable to accept your order for any reason, we will inform you of this orally or in writing and will not charge you for the product and services. If you have not received a confirmation of your order via email or mobile messaging, please contact us at admin@spacium.ae or call +971 504000604. The Site is in no way responsible nor liable to accept all and any orders, transactions. There is no compulsion upon the Site for any of the aforementioned.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.3
            Modifying Order. Orders should be placed carefully, as any changes made after confirmation, once sent out, will incur additional charges.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.4
            Payment. By placing an order, you authorise us and/or our third-party payment processer to process your credit/debit card details for the amount of your order. Visa or MasterCard debit and credit cards in AED will be accepted for payment. We accept payment in United Arab Emirates Dirhams (“AED”) only by:
            a) Visa or MasterCard credit/debit card;
            b) Via your wallet on the Platform; or
            c) Cash on delivery (an amount not exceeding AED 1835)
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.5
            In order to authorise credit/debit card payments, we may be required to create an account for you with our third-party payment processors, including accepting their standard terms and conditions and submitting your details to them on your behalf. You hereby authorise us to do so and we shall not be liable to you for any damage or loss you may incur as a direct or indirect result of making payments on the Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.6
            Acceptable modes of payment may be amended/updated at any time without prior notice to you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.7
            If you are willing to pay on “instalment Plans” it is your responsibility to discuss it with your bank in advance, as this will not be Spacium's responsibility.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.8
            Cancelling Order. Products may be cancelled for any reason after the order is placed and prior to dispatch, subject to the Cancellation Policy. Please refer to the Cancellation Policy for more information.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.9
            Our Cancellation. We may cancel your order(s) if:
            a. You do not make any payment to us when it is due;
            b. You do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the products; or
            c. You do not, within a reasonable time, allow us to deliver the products to you or collect them from us;
            d. You attempt to bulk or multi-order purchase in accordance with clause 17.10, below; or
            e. Order(s) cannot be fulfilled due to unavailability in inventory.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.10
            Bulk/Multiple Purchasing. We reserve the right to reject any orders, at our sole discretion, where we detect bulk purchasing or multiple units of similar products being purchased.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.11
            Queries and Complaints. Cardholder must retain a copy of transaction records for purchases made on the Platform as well as these Terms and other Spacium policies for any queries or complaints you may have.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            19.12
            Vouchers and Credits. To avail any vouchers or credit, you must create a Spacium account with your personal email or login to your existing account. Click “My Account” then “Spacium Credits” and manually enter the code on your gift voucher for the credit to appear in your account. Expired gift vouchers will not be valid to be used on the Platform. Once you redeem the voucher and the Spacium credits are on your account, there is no expiry on the amount. You can use your credits on any iOS or Android device which has our Spacium app installed.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            20. DELIVERY OF YOUR ORDER
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.1
            Delivery Costs. The estimated costs of delivery will be displayed to you on our Site at the time of purchase. Delivery fees are subject to change in accordance with the supplier and/or 3PL.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.2
            Delivery Date.
            This information will be displayed to you on our Site at the time of purchase as an estimated delivery date. They are not guaranteed delivery times and may differ despite our best efforts. Spacium will not be responsible or held liable for damages to products while in-transit or for delays. In case of delays, Spacium will take reasonable measures to fulfil the order as per Clause [18.4 – Delivery Delays] below.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.2
            Delivery Information
            You shall be solely responsible for providing the correct, specific and accurate address and contact details for Spacium, suppliers and/or 3PL to deliver any products that you have purchased on the Platform. Spacium, suppliers and/or 3PL will not be responsible for any delays in shipping due to inaccurate, false or incomplete information provided by you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.3
            Delivery Delays:
            a. If our delivery of the Product is delayed by an event outside of our control, then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay;
            b. If no one is available at your address to take delivery and the products cannot be posted through your letterbox, we will notify you of how to rearrange delivery or collect the product;
            c. If you do not collect the Product from us as arranged or if, after a failed delivery to you, you do not re-arrange delivery or collect them from a delivery depot, we will contact you for further instructions. If, despite our reasonable efforts, we are unable to contact you or re-arrange delivery or collection, we will cancel your order.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.4
            ID Requirement upon Delivery. We may, at our sole discretion, request documentation/proof deemed necessary to verify your identity and/or ownership of your financial instruments by requesting that you provide us with further information or documentation, including, but not limited to requesting a form of identification and/or credit card. If we are unable to verify or authenticate any information you provide, we have the right to refuse delivery and cancel the order.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.5
            Delivery from Overseas. In the event that your order requires delivery from overseas countries, you may be considered the importer for the purposes of paying all required fees and customs duties relevant to your purchase. In such instance, you are required to ensure that your ordered Products can be lawfully imported and comply with all applicable laws and regulations. Spacium shall not trade with or provide any services to OFAC (Office of Foreign Assets Control) and sanctioned countries in accordance with UAE laws and regulations.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.6
            Title to Products. ATitle and ownership of a Product shall be transferred to you upon successful delivery of the product. In case any product is returned or rejected by you, the title and ownership of the Product shall, immediately upon return or rejection, revert to the Supplier who shall be the sole and undisputed owner of the product
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.7
            Invoice. An electronic invoice for your purchase shall be issued to the registered email address on your account. It is your responsibility to update your email address and mark our email addresses as not “spam” to continue to receive important communication from Spacium regarding any of your purchases.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.8
            Cash on Delivery. Cash on Delivery (“COD”) will be available only for orders that do not exceed AED 1835. An additional fee of AED 10 will be charged for any COD orders.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            20.9
            Missing Orders. If, at any time after the purchase has been made on the Platform and you have received the written confirmation of the order, a Product is missing from your order at the time of delivery, you must inform Spacium within 2 business days, in order for Us to be able to arrange for the delivery of the missing Products.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            21. RETURNS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.1
            The table below sets out our conditions for returns: And the Customer must present receipt for Spacium to accept return order.
            Reasons for Return	Possibility of Return	Eligibility Criteria
            YOU HAVE RECEIVED A WRONG PRODUCT	YOU MUST RETURN THE PRODUCT WITHIN FOURTEEN (14) DAYS OF RECEIPT OF SHIPMENT.	PRODUCTS ARE IN THEIR ORIGINAL CONDITION, PACKAGING AND LABELLING;
            YOU HAVE RECEIVED A PRODUCT THAT IS NOT AS DESCRIBED ON THE SITE;		YOU PROVIDE PROOF OF PURCHASE (I.E. COMPANY ISSUED RECEIPT, BANK MESSAGES ARE NOT SUFFICIENT ON THEIR OWN);
            YOU HAVE RECEIVED A DAMAGED PRODUCT (IN CASE OF A MANUFACTURING DEFECT, PLEASE REFER TO THE RELEVANT POLICY NAMED “MANUFACTURING DEFECTS POLICY”); OR		YOU HAVE NOT OPENED, UNSEALED, UNPACKED, ASSEMBLED, USED, ALTERED OR INSTALLED THE PRODUCTS.
            ANY OTHER REASON AS LONG AS THE ELIGIBILITY CRITERIA IS FULFILLED.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.2
            Non-returnable Products: You do not have a right to return, replace or exchange products that are:
            a. Made-to-order products;
            b. Customized products;
            c. Unwrapped products;
            d. Sale products which are not defective or are sold with a tag ‘as is’;
            e. Damaged products due to misuse or showing signs of wear and tear, even if they are still under warranty period.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.3
            Contacting Us (arrange a Return). You may contact us through email, social media or live chat on the Site, or by calling on +971 504000604 (UAE) or outside UAE within fourteen (14) days of the delivery of the products. Please read our Returns policy for more details on the return procedure.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.4
            Your Refund: You are eligible for a partial/full refund in the below cases. Please read the Refund Policy for more information.
            a. You have cancelled your order prior to dispatching of the products and in compliance with the Cancellation Policy; or
            b. You have submitted a Return Application within Fourteen (14) days of the Products’ delivery in compliance with the Return Policy;
            c. One or more of the Products include a Manufacturing Defect which is not capable of being fixed;
            d. One or more products are missing from the order and you have informed us within two (2) business days of delivery as per Clause 18.9.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.5
            Return Cost. If the delivered products are faulty or not as described on our Site; or if your reason for return is due to an error on our side, such as an error in pricing or description, a delay in delivery etc., we will refund to you the full Product amount (excluding the amount paid for the original shipping fees) plus the cost of return. In all other circumstances, we will refund the Product amount (excluding the amount paid for the original shipping fees) and you will be responsible to pay for the costs of shipping for the return.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.5
            Refund Procedure. Refunds will be issued to the original mode of payment chosen when you made your order, as follows:
            a. If you paid by COD, we will issue a refund to your wallet via store credit to use on the Platform; or
            b. If you paid by credit/debit card, the refund will be issued to your respective card.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            21.6
            Refund Timescale. Your refund will be initiated after your Product is received back in our customer fulfilment center and inspected by our team , and the final refund will be received by you as per the email refund confirmation you receive and as follows:
            a. If the refund is to your credit/debit card, the exact date on which the amount shall be reflected in your account depends on your bank/payment service provider. In case we have confirmed successful refund through email, please contact your bank/payment service provider for follow ups;
            b. If the refund is to your wallet, you will receive the refund immediately after your product is received back in our customer fulfilment center and inspected by our team; or c. If you have cancelled your order before shipping, an automated refund will be provided back to you via the respective payment method chosen.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            21. COOKIES
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Spacium may use cookies, or other technologies, on this site (“Cookies”). Cookies are small files or records that Spacium may place on your browser for collecting information about your activities on the Site, preparing reports, assessing trends, and otherwise monitoring the way in which the Site is being used. This is intended to enhance convenience for you, customize your visit to the Site, and to assist Spacium to run an efficient operation. Cookies can be created automatically on the device you use to access the Site but do not contain personal information and do not compromise your privacy or security. By accepting these Terms, you confirm your consent to Spacium’s use of Cookies, and other such technologies, for these purposes. If you have expressly contacted us, are browsing the Site and/or expressed an interest in the Products, you have consented and agreed to this information being collected and stored. If you do not consent, it is your responsibility to disable or restrict the placement of cookies by configuring your browser settings which may affect your ability to use the Site or to immediately refrain from using the Site.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            22. ELECTRONIC COMMUNICATIONS
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            To fulfil our obligations to you under these Terms, we may wish to communicate with you by email, other electronic messaging and/or by posting notices on the Platform. We will use the email or phone number provided by you in your account for such communications. You agree to receive communications from us electronically and these electronic communications will satisfy any legal requirement for communications, which need to be in writing. If you submit someone else’s contact details to us to receive communications from Spacium, you represent and warrant that each person for whom you provide a phone number or email address has consented to receive communications from Spacium. If you wish to stop receiving promotional emails or promotional text messages, you may opt-out or unsubscribe by using your account settings.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            TERMS APPLICABLE TO SPECIAL SPACIUM SERVICES
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            iOS SPACIUM App
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            In addition to your agreement with these Terms, the following provisions apply with respect to your use of any version of the Spacium App compatible with the iOS operating system of Apple Inc. (“Apple”).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Apple is not a party to these Terms and does not own and is not responsible for any technology, software or content on the Spacium App. Apple is not providing any warranty for the iOS Spacium App except, if applicable, to refund the purchase price for it at its discretion. Apple is not responsible for maintenance or other support services for the iOS Spacium App and will not be responsible for any other claims, losses, liabilities, damages, costs, or expenses with respect to the Spacium App, including any third-party product liability claims, claims that the iOS Spacium App fails to conform to any applicable legal or regulatory requirement, claims arising under consumer protection or similar legislation, and claims with respect to intellectual property infringement. Any inquiries or complaints relating to the use of the iOS SpaciumApp, including those pertaining to intellectual property rights, must be directed to Spacium.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The right you have been granted in these Terms is limited to a non-transferable licence to use the Spacium App on an Apple-branded product that runs Apple’s iOS operating system and is owned or controlled by you, or as otherwise permitted by the “Usage Rules” set forth in Apple’s App Store Terms of Service. In addition, you must comply with the terms of any third-party agreement applicable to you when using the SpaciumApp, such as your wireless data service agreement.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            Android Spacium App
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Spacium, and not Google Inc. (“Google”), is solely responsible for the Android Spacium App. Google has no obligation to provide maintenance and support for the Android Spacium App.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Google is not responsible for addressing, investigating, defending, settling, or discharging any claim brought by you or any third party for allegations relating to the Android Spacium App, or your possession and/or use of the Android Spacium App, including but not limited to: (a) product liability; (b) any failure of the Android Spacium App to comply with applicable legal or regulatory requirements; (c) consumer protection or similar legislation; or (d) infringement of third party intellectual property rights.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            You as a user of the Android Spacium App represent and warrant that: (a) the Android Spacium App will not be downloaded in, used in, or transported to a country that is subject to a U.S. Government embargo or EU government sanctions, or that has been designated by the U.S. Government or any EU government as a "terrorist-supporting" country or similar; and (b) neither Customer nor User is listed on any U.S. Government or EU government list of prohibited or restricted parties.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            To the maximum extent permitted by applicable law, Google will have no warranty obligation whatsoever with respect to the Android Spacium App, and will not be liable for any claims, losses, liabilities, damages, costs, or expenses attributable to any failure of the Android Spacium App to conform to any warranty. Google shall not be required to provide a refund to you under any circumstances.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            23.
            NOTICE Any notice, approval, consent, waiver or other communication sent by you to Spacium in regards to these Terms shall be in writing and shall be sufficiently given if delivered personally, transmitted by fax, sent by registered mail, return receipt requested, or by e-mail (provided that you received electronic confirmation of receipt by recipient) or other form of recorded written communication tested prior to transaction, to Spacium as follows:
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Al Murror road, Al Sa'adah area,
            Abu Dhabi, UAE
            PO Box: N/A
            Attention:
            Telephone: 050 4000604
            E-mail: admin@spacium.ae
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            24. HOW YOU CAN CONTACT US
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The Site is operated by Spacium, if you have any concerns about your Information on the Platform, opinions, comments, feedback, messages or contact relating to the Site, please contact us at admin@spacium.ae with a thorough description, and we will try to address/resolve it.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              25.
              AVAILABILITY AND HOURS OF OPERATION
            </Typography>
            &nbsp; The Site is available 24 hours, 7 days a week to the User. If you would like to contact Spacium or the Site administrators, our hours of operation are Monday to Friday, 9 am to 5 pm
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component="span" sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              26.
              LAST AMENDMENT
            </Typography>
            &nbsp; This disclaimer/Terms was amended for the last time on _____.
          </Typography>
        </Box>
      </Container>
    </SModal>
  )
}

export default Terms