import {
  AppBar,
  Box,
  Button,
  FilledInput,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import logoImg from "assets/logo.png";
import SInput from "common/material/panel/SInput";
import { FileUploader } from "react-drag-drop-files";
import { Close } from "@mui/icons-material";
import Toaster from "common/Toaster";
import DropImg from "assets/drop-img-icon.png";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import colors from "utils/colors";

const categories = [
  "Hand Painted",
  "Hand Work",
  "Aluminum Work",
  "Marbles & Ceramic Tiles",
  "Handmade",
  "Woodwork",
  "Carpentary",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CustomizeIt = () => {
  const theme = useTheme();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const fileTypes = ["png", "jpeg", "jpg"];

  const [defaultImage, setDefaultImage] = useState("");
  const [images, setImages] = useState([]);
  const [location, setLocation] = useState();
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({ name: "", link: "" });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRemovePicture = (imageIndex) => {
    const newImages = images.filter((item, i) => i !== imageIndex);
    setImages(newImages);
  };

  function RenderImageComponent({ source, onRemoveImage }) {
    return (
      <Grid item md={3}>
        <Box
          sx={{
            position: "relative",
            border: "2px solid #F3F3F3",
            borderRadius: "10px",
            width: "100%",
            height: "170px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
            padding: 1,
          }}
        >
          <img
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            src={source}
            alt="not found"
          />
          <IconButton
            sx={{
              width: "25px",
              height: "25px",
              background: "rgba(0,0,0,.2)",
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
            onClick={() => onRemoveImage()}
          >
            <Close fontSize="small" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Grid>
    );
  }

  function RenderImageUploadComponent({
    title,
    imageKey,
    sizeError,
    onImageSelection,
    pickTitle,
    md,
  }) {
    return (
      <Grid item md={md ? md : 3} flex={1}>
        <FormControl sx={{ width: "100%" }}>
          <FileUploader
            name="file"
            types={fileTypes}
            maxSize={1}
            onSizeError={(file) => {
              Toaster({
                type: "error",
                message: sizeError,
              });
            }}
            onTypeError={(file) => {
              Toaster({
                type: "error",
                message:
                  "File type not supported. Supported file types are " +
                  fileTypes.join(", ") +
                  ".",
              });
            }}
            onDrop={(file) => onImageSelection(file)}
            onSelect={(file) => onImageSelection(file)}
            children={
              <Box
                sx={{
                  border: "2px dashed #F3F3F3",
                  position: "relative",
                  borderRadius: "10px",
                  width: "100%",
                  height: "170px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={DropImg} alt="not found" />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Book45",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {title}
                  <br />
                  or{" "}
                  <Box
                    component="span"
                    sx={{
                      fontFamily: "Heavy85",
                      color: "#006156",
                      textDecoration: "underline",
                    }}
                  >
                    Browse
                  </Box>
                </Typography>
              </Box>
            }
          />
          <FormLabel
            component="legend"
            sx={{
              color: "#000",
              fontFamily: "Roman55",
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            {pickTitle}
            <Tooltip title={""} placement="right">
              <Box
                component="span"
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "21px",
                  width: "21px",
                  padding: "2px",
                  ml: 1,
                  background: "#707070",
                  borderRadius: "50%",
                  color: "#fff",
                }}
              >
                ?
              </Box>
            </Tooltip>
          </FormLabel>
        </FormControl>
      </Grid>
    );
  }
  return (
    <div>
      <AppBar
        sx={{
          background: "#fff",
          boxShadow: "0 4px 6px rgba(0,0,0,.03)",
          width: `100%`,
          overflow: "none",
          py: 2,
          // ml: `${drawerWidth}px`,
        }}
      >
        <Toolbar sx={{ display: "flex", gap: 10 }}>
          <img src={logoImg} alt="logo" width={100} />
          <Typography sx={{ color: "#000", fontSize: 22 }}>
            Custom Vendor Registeration
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container mt={7} sx={{ py: "5rem", px: "1.8rem" }}>
        <Typography sx={{ color: "#000" }}>Custom Vendor Profile</Typography>
        <Grid container sx={{ gap: 10, pt: 3 }}>
          <Grid
            item
            flex={1}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <SInput label="Full Name" />
            <SInput label="Business Name" />
            <SInput label="Email" />
            <SInput label="Years of Experience" />
            <SInput label="Contact No." />
            <SInput label="Address" />

            <FormControl>
              <InputLabel id="multiple-name-label">Categories</InputLabel>
              <Select
                labelId="multiple-name-label"
                id="multiple-name"
                multiple
                value={selectedCategories}
                onChange={handleChange}
                input={
                  <FilledInput sx={{ color: "#000" }} label="Categories" />
                }
                sx={{
                  background: "#F3F3F3",
                  borderRadius: "5px",
                  fontFamily: "Book45",
                  fontSize: "1.1rem",
                  px: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // background: '#F3F3F3',
                      border: "none",
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                  },
                  "& .MuiInputBase-input": {
                    paddingLeft: "7px",
                  },
                  "& ::placeholder": {
                    fontFamily: "Roman55",
                  },
                }}
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    style={getStyles(category, selectedCategories, theme)}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <SInput label="Summary" multiline row={5} />

            <Typography sx={{ fontWeight: "bold", color: "#000" }}>
              Choose Location from the map
            </Typography>
            <div style={{ height: 500, width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={{
                  lat: 25.2048,
                  lng: 55.2708,
                }}
                defaultZoom={7}
                options={{}}
                onClick={(e) => {
                  console.log({ e });
                  setLocation({
                    lat: e.lat,
                    lng: e.lng,
                  });
                }}
              ></GoogleMapReact>
            </div>
            <Box sx={{ display: "flex", gap: 3 }}>
              <SInput label="Latitude" value={location?.lat} />
              <SInput label="Longitude" value={location?.lng} />
            </Box>
          </Grid>
          <Grid item flex={1} sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container width={"100%"}>
              {defaultImage ? (
                <RenderImageComponent
                  source={
                    typeof defaultImage === "string"
                      ? defaultImage
                      : URL.createObjectURL(defaultImage)
                  }
                  onRemoveImage={() => {
                    setDefaultImage("");
                  }}
                />
              ) : (
                <RenderImageUploadComponent
                  title={"Drag your image of MAX 400KB here,"}
                  imageKey={"default_image"}
                  sizeError={"File should not be more than 400 KB"}
                  pickTitle={"Cover"}
                  onImageSelection={(file) => {
                    console.log({ file });
                    setDefaultImage(file);
                  }}
                />
              )}
            </Grid>
            <Grid container>
              {images?.map((item, index) => {
                return (
                  <RenderImageComponent
                    source={URL.createObjectURL(item)}
                    onRemoveImage={() => {
                      handleRemovePicture(index);
                    }}
                  />
                );
              })}

              <RenderImageUploadComponent
                title={"Drag your image of MAX 400KB here,"}
                imageKey={"default_image"}
                sizeError={"File should not be more than 1 MB"}
                onImageSelection={(file) => {
                  setImages([...images, file]);
                }}
                pickTitle={"Add More"}
                md={4}
              />
            </Grid>
            <Box sx={{ color: "#000" }}>
              <Typography>Links</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {links.map((link, index) => {
                  return (
                    <Paper
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography>Name: {link.name}</Typography>
                        <Typography>Link: {link.link}</Typography>
                      </Box>
                      <Box sx={{ cursor: "pointer" }}>
                        <div
                          onClick={() => {
                            const newLinks = links.filter(
                              (item, i) => i !== index
                            );
                            setLinks(newLinks);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            color={colors.error}
                          />
                        </div>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
              <Paper sx={{ p: 2, mt: 2 }}>
                <SInput
                  label="Name"
                  value={newLink.name}
                  onChange={(e) => {
                    setNewLink({ ...newLink, name: e.target.value });
                  }}
                />
                <SInput
                  label="Link"
                  value={newLink.link}
                  onChange={(e) => {
                    setNewLink({ ...newLink, link: e.target.value });
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    setLinks([...links, newLink]);
                    setNewLink({ name: "", link: "" });
                  }}
                >
                  Add More
                </Button>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        sx={{
          background: "#006156",
          color: "#fff",
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default CustomizeIt;
