import { actionType } from "appRedux/actionType";

export function sellerRegistration(payload) {
  return {
    type: actionType.SELLER_REGISTER_METHOD,
    payload: payload,
  };
}

export function sellerLogin(payload) {
  return {
    type: actionType.SELLER_LOGIN_METHOD,
    payload: payload,
  };
}

export function sellerLogout(payload) {
  return {
    type: actionType.SELLER_LOGOUT_METHOD,
    payload: payload,
  };
}

export function changePassword(payload) {
  return {
    type: actionType.UPDATE_PASSWORD_METHOD,
    payload: payload,
  };
}
