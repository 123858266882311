import { actionType } from "appRedux/actionType";
import Cookies from "js-cookie";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: "",
};

const setLogin = (data) => {
  Cookies.set("token", data.token);
  Cookies.set("email", data.email);
  Cookies.set("name", data.name);
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.SELLER_LOGIN_METHOD:
      return { ...state, loading: true, error: "" };
    case actionType.SELLER_LOGIN_SUCCESS:
      setLogin(action.payload.data);
      return {
        ...state,
        loading: false,
        error: "",
        res: action.payload,
      };
    case actionType.SELLER_LOGIN_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}

export function updatePassword(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.UPDATE_PASSWORD_METHOD:
      return { ...state, loading: true, error: "" };
    case actionType.UPDATE_PASSWORD_SUCCESS:
      setLogin(action.payload.data);
      return {
        ...state,
        loading: false,
        error: "",
        res: action.payload,
      };
    case actionType.UPDATE_PASSWORD_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.UPDATE_PASSWORD_CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        res: "",
      };

    default:
      return state;
  }
}
