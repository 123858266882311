import { Button } from "@mui/material";
import React from "react";

const SButton = (props) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        fontFamily: "Medium65",
        fontSize: "1rem",
        boxShadow: "none",
        ...props.mySx,
      }}
      size={props.size}
      variant={props.variant}
      onClick={props.onClick}
      className={props.className}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default SButton;
