import { Box, Grid, Typography } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import { API_CITY_CHOICE, API_STORE } from "appRedux/apiType";
import SButton from "common/material/panel/SButton";
import SInput from "common/material/panel/SInput";
import SSelect from "common/material/panel/SSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "utils";

const Address = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("United Arab Emirates");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [citiesList, setCitiesList] = useState([]);

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );
  const disabled = !!profileRes?.seller_agreement_date;

  const fetchCities = () => {
    const apiData = {
      type: `${API_CITY_CHOICE}`,
      apiType: "GET",
    };
    apiCallV2(apiData)
      .then((res) => setCitiesList(res.state))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };
  const save = () => {
    const apiPayload = {
      country: 217,
      state: city,
      city: city,
      street_1: street,
      zip_code: zipcode,
      latitude: "",
      longitude: "",
    };

    const apiData = {
      type: `${API_STORE}add-address/`,
      apiType: "POST",
      payload: apiPayload,
    };
    if (profileRes.address && profileRes.address.id) {
      apiData.type = `${API_STORE}${profileRes.address.id}/address/`;
      apiData.apiType = "PATCH";
    }
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  const handleSetData = () => {
    setCountry(profileRes.address.country);
    setState(profileRes.address.state);
    setCity(profileRes.address.city);
    setStreet(profileRes.address.street_1);
    setZipcode(profileRes.address.zip_code);
  };

  useEffect(() => {
    if (profileRes && profileRes.address) {
      handleSetData();
    }
  }, [profileRes]);

  useEffect(() => {
    fetchCities();
  }, []);

  function inputContainer(count, children, sm, sx) {
    return (
      <Grid sx={[{ mb: 2, pr: 3, display: "flex" }, sx]} sm={sm}>
        <Box sx={{ width: "30px" }}>
          <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
            {count}.
          </Typography>
        </Box>
        {children}
      </Grid>
    );
  }

  return (
    <Box>
      <Grid container sx={{ mb: 2 }}>
        <Grid item sx={{ width: "100%" }}>
          {inputContainer(
            1,
            <SInput
              label={t("street")}
              value={street}
              disabled={disabled}
              onChange={(e) => setStreet(e.target.value)}
            />
          )}
          {inputContainer(
            2,
            <SInput label={t("country")} value={country} disabled={true} />
          )}

          <Grid sx={{ display: "flex" }}>
            {citiesList && (
              <>
                {inputContainer(
                  4,
                  profileRes?.seller_agreement_date ? (
                    <SInput
                      label={t("city")}
                      value={city}
                      disabled={disabled}
                    />
                  ) : (
                    <SSelect
                      id="city_select"
                      placeholder={t("city")}
                      label={t("city")}
                      displayKey="display"
                      valueKey="key"
                      options={citiesList || []}
                      value={city || ""}
                      disabled={disabled}
                      onChange={(e) => setCity(e.target.value)}
                      // error={error.country && error.country}
                    />
                  ),
                  6
                )}
              </>
            )}
            {inputContainer(
              5,
              <SInput
                label={t("zip_code")}
                type="number"
                value={zipcode}
                disabled={disabled}
                onChange={(e) => setZipcode(e.target.value)}
              />,
              6
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SButton
            mySx={{ borderRadius: "25px", width: "140px" }}
            disabled={disabled}
          >
            {t("cancel")}
          </SButton>
          <SButton
            variant="contained"
            mySx={{ borderRadius: "25px", width: "140px", ml: 4 }}
            disabled={disabled}
            onClick={(e) => save()}
          >
            {t("save")}
          </SButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default Address;
