import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: ""
}

export default function deleteProduct(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.DEL_PRODUCT_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.DEL_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.DEL_PRODUCT_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.DEL_PRODUCT_CLEAR:
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}


export  function deleteVariant(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.DEL_VARIANT_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.DEL_VARIANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.DEL_VARIANT_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.DEL_VARIANT_CLEAR:
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}

export  function deleteVariantImage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.DEL_IMG_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.DEL_IMG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.DEL_IMG_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.DEL_IMG_CLEAR:
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}