import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: ""
}

export default function getMagazine(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.GET_MAGAZINE_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.GET_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.GET_MAGAZINE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.GET_MAGAZINE_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        res: ''
      };

    default:
      return state;
  }
}

export function getMagazineCategories(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.GET_MAGAZINE_CATEGORY_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.GET_MAGAZINE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.GET_MAGAZINE_CATEGORY_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.GET_MAGAZINE_CATEGORY_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        res: ''
      };

    default:
      return state;
  }
}

export function createMagazine(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.POST_MAGAZINE_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.POST_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.POST_MAGAZINE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.POST_MAGAZINE_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        res: ''
      };

    default:
      return state;
  }
}

export function updateMagazine(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.UPDATE_MAGAZINE_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.UPDATE_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.UPDATE_MAGAZINE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.UPDATE_MAGAZINE_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        res: ''
      };

    default:
      return state;
  }
}

export function deleteMagazine(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.DEL_MAGAZINE_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.DEL_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.DEL_MAGAZINE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.DEL_MAGAZINE_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        res: ''
      };

    default:
      return state;
  }
}

