import React from 'react';
import SModal from 'common/material/panel/SModal';
import { Container, Box, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  px: 4,
  py: 2,
  border: 'none',
  width: '80vw'
};

const Policy = ({ open, handleClose }) => {
  return (
    <SModal
      open={open}
      handleClose={handleClose}>
      <Container sx={style}>
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.75rem' }}>
            Privacy Policy
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              background: '#F5F5F5',
              right: 0,
              top: 0
            }}
            onClick={handleClose}
          >
            <Close fontSize='small' color="#000" />
          </IconButton>
        </Box>
        <Box sx={{ my: 3, maxHeight: '70vh', overflowY: 'auto' }}>
          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.3rem', mb: 2 }}>
            OVERVIEW
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Spacium.ae is strongly committed to respecting the privacy of all persons using our websites and mobile applications (the “Platform") and the protection of any personally identifiable information which we may collect on our Websites/Apps and/ or use as part of our data collection process and/ or which you may choose to share with us in our stores or via telephone, email or otherwise (“the Other Channels”). Such personal information will be collected and/ or used in accordance with the terms and conditions of this Privacy Policy.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            This Privacy Policy describes what personal information we collect, how we use this information, how this information may be shared, and what choices and rights you have with respect to this information. Access and use of our Platform is subject to this Privacy Policy.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The terms “we,” “us,” “our” or “Spacium” refer to Spacium Furnitures – Sole Proprietorship LLC. By “you,” we are referring to visitors and users of any part of our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            1. Collection of Information
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We collect personal information that you provide us, from third parties, and automatically when you use our Platform. We collect information associated with your device using cookies and similar technologies. We may also receive information about your location.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
            Information You Provide Us
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We collect information that you provide us, including your account and profile information, content you submit or post to our Platform, your purchase information, your communications, and information you provide to us as part of surveys and contests.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>Account and Profile Information.</Typography> &nbsp; When you create an account on our Platform, we collect information you provide us, like your name, email address, interests, gender, age, and profile information. If you are a professional, you may also provide us information related to your business, including a business description and areas served.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Content You Submit. &nbsp;
            </Typography>
            We collect information and content that you submit, post to, or create in our Platform, including your photos, comments, reviews, searches, photo notes, ideabooks, discussions, project details and costs, style preferences, and as a professional, other types of content provided for in our Spacium management tools.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Purchases. &nbsp;
            </Typography>
            If you purchase or sell products or services or make or receive payments within our Platform, we collect certain information in order to complete your purchases, sales, and payments, such as your phone number, and address. If you participate in our Trade Program or Business Program, you may be asked to provide proof that you are eligible for the program or for a tax exemption.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Communications. &nbsp;
            </Typography>
            When you communicate with us (via email, phone, through our Platform, or otherwise) and when you use our Platform to communicate with others, we may record, monitor, or otherwise collect details about your communications (including call time, and call duration). Certain professionals and sellers on our Platform may choose to use our call tracking feature in order to better manage their business. You are using our Platform when you make calls to these professionals and sellers using their phone number displayed on our Platform. We will provide you notice or obtain your consent in accordance with applicable law before recording or monitoring any of these calls. Otherwise, to the extent you communicate with professionals, sellers, and other users outside our Platform, those communications are not subject to this Privacy Policy.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Surveys and Contests. &nbsp;
            </Typography>
            If you participate in our surveys, contests, or promotions, we will collect the information you provide to us.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
            Information from Others
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We collect information that others make available to us, including information from other platforms, publicly available information, information from other users of our Platform, information from our partners, service providers, and corporate affiliates, and information from third-party sites.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Other Platforms.&nbsp;
            </Typography>
            We may receive information about you from third-party platforms when you allow the third-party platform to share this information with us or allow Spacium to obtain this information from the third party. For example, you may sign-up for our Platform through Facebook, Google, or Apple; upload your contacts from your third-party email service; use our Platform (or third-party integration on our Platform) to manage your calendar on another platform or to manage and send communications (e.g., email or text messages).
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Publicly Available Information. &nbsp;
            </Typography>
            If you are a professional or seller, we may collect information about you from publicly available sources and where permitted by applicable law or with your permission, make it available as part of our Platform. For example, we may receive your name and contact information from industry associations and dealer networks in which you participate and display your affiliation with these organizations. We also may collect and display the details of any license you have using publicly available records.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Other Users and Customers. &nbsp;
            </Typography>
            Others may post content on our Platform that includes information about you (for example, as part of photos, stories, and reviews) or provide us your information in connection with using our Platform (e.g., to manage their business, organize photo shoots of their projects, participate in referral programs, and to advertise through our Platform). In order to enhance the quality of our Platform, professionals and sellers on our Platform may provide us information about your engagement with them, including whether you have contacted them or responded to them, whether your project has been completed, and whether you have received a product you have ordered. If you are a professional or seller, Platform users and visitors may provide us with feedback and public reviews about you.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Our Partners and Service Providers. &nbsp;
            </Typography>
            We may receive information about you from our partners and service providers. For example, our payment processors may update your payment information, advertising partners may help us send communications to your address, and third parties may help us prevent fraud by verifying your contact information. In addition, our banking partner that issues the Spacium credit card(s) may provide us with information regarding whether you have such a card, related to any card reward or loyalty program, or resulting from pre-screening you for card offers.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Third-Party Sites. &nbsp;
            </Typography>
            Certain third-party sites embed a Spacium plug-in, widget, or content, including through Spacium-branded buttons, badges, and slideshows and Spacium co-branded content partnerships. Our plug-ins, widgets, embedded content, and pixels may collect information pursuant to our Cookie Policy. You may also choose to use our browser extensions, which collect the information described in this Privacy Policy like the rest of our Platform. https://www.spacium.ae/ will not pass any debit/credit card details to Third Parties. Spacium is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <Typography component='span' sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
              Site Designer Websites.&nbsp;
            </Typography>
            Certain professionals' sites are developed or hosted by us (“Site Designer Website”). As with the rest of our Platform, we collect the information described in this Privacy Policy on the Site Designer Websites, including your messages to the professionals sent through those sites. These professionals collect usage information for their Site Designer Websites and receive your messages sent through those sites.
          </Typography>


          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
            Automatically Collected Information
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Each time you access the Platform; information is sent to our server by your Internet browser or app and stored in our log files. We collect information about your use of our Platform, including your search activity, the pages you view, the date and time of your visit, and if you use our browser extensions, the content you interact with on third-party sites (for example, if you save an image from a third-party site to your Spacium account), and how you navigate and interact with a page or screen. We also collect information that your device provides to us in connection with your use of our Platform, such as your browser type, type of device, browser language, IP address, mobile carrier, unique device identifier, and requested and referring URLs.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
            Cookie Policy
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            To ensure our website and mobile application works correctly, we may at times place a small piece of data known as a cookie on your computer or mobile device. Cookies are small files or records that Spacium may place on your browser for collecting information about your activities on the Platform, preparing reports, assessing trends, and otherwise monitoring the way in which the Platform is being used. (“Cookies”). The content of a cookie can be retrieved or read only by the server that creates the cookie. The text in a cookie often consists of identifiers, site names, and some numbers and characters. Cookies are unique to the browsers or mobile applications you use, and enable websites to store data such as your preferences or items in your shopping cart.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Like many other websites or Internet service providers, Spacium may use Cookies, or other technologies, on this Platform to improve your user experience. Session cookies are deleted after each visit, while persistent cookies remain in place across multiple visits. Cookies allow websites to remember your settings such as language, font size on your computer or mobile device, or other browser preferences. This means that a user does not need to reset preferences for every visit. On the contrary, if cookies are not used, websites will treat you as a new visitor every time you load a web page. For example, if you are redirected to another web page from a website you are already logged in to and then return to the original website, it will not recognize you and you must log in again.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            The type of Cookies and other tracking technologies used on our Platform can be categorised as essential Cookies and non-essential Cookies. Essential Cookies are required for the functional operation of the Platform and are used to, but not limited to, identify you while using the Platform, remember Products that you have added to your basket, remember settings you have applied on the Platform such as choice of language or region. Non-essential Cookies relate to visitor and website performance, targeting and advertising Cookies for personalisation of adverts as well as Third Party Cookies, as Spacium may display content from a third-party website or service, which may result in cookies from third parties being stored on your hard drive or browser. Please note Spacium does not control the storage and use of these cookies by third parties and you should refer to the privacy policies of the relevant third parties for more information.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Our use of Cookies on the Platform is intended to enhance convenience for you, customize your visit to the Platform, and to assist Spacium to run an efficient operation. Cookies can be created automatically on the device you use to access the Platform but do not contain personal information and do not compromise your privacy or security. By accepting this Privacy Policy, you confirm your consent to Spacium’s use of Cookies, and other such technologies, for these purposes. If you have expressly contacted us, are browsing the Platform and/or expressed an interest in the Products, you have consented and agreed to this information being collected and stored. If you do not consent, it is your responsibility to disable or restrict the placement of Cookies by configuring your browser settings which may affect your ability to use the Platform or to immediately refrain from using the Platform. Please note that turning off or deleting cookies will not prevent device identification and related data collection from occurring.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1rem', mb: 2 }}>
            Location Information
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We will receive your device's location if you enable this through your browser or mobile device. We may infer your general location from your IP address. You may also choose to provide us your location information by inputting this information in our Platform, including in your Spacium settings, or when you checkout as a shipping or billing address.
          </Typography>


          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            2. Use of Information
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Except as otherwise stated below, we do not sell, trade or rent your personally identifiable information collected on the site to others. We use the personal information we collect to provide, support, and personalize our Platform and to communicate with you about our Platform (on and off our Platform). The ways we do this include the following: <br />
            a. to provide and improve our Platform and its features and functionality, including to display content you post publicly, fulfill your purchases through our Platform, process, store, and deliver your communications; to provide customer service and support; to respond to your inquiries or requests; to back-up our systems; and to improve the security of our Platform;<br />
            b. to evaluate use of our Platform for our business purposes and to provide and improve our products and services, including new and co-branded products and services and our Platform's functionality;<br />
            c. to personalize your experience, such as tailoring the content and information made available to you and our support services; d. to display relevant advertising (on our Platform and third-party sites) for us and other businesses and to evaluate the success of such advertising;<br />
            e. to provide feedback to professionals and sellers on our Platform;<br />
            f. to communicate with you as described in our agreements with you (for example, the Spacium Terms of Use) or this Privacy Policy;<br />
            g. to prevent fraud and to maintain a quality experience for our business purposes or legal reasons, including to filter for spam and communications that violate our agreements with you (for example, the Spacium Terms of Use);<br />
            h. to enforce compliance with our agreements with you (for example, the Spacium Terms of Use) and applicable law; to comply with our legal obligations; to prosecute or defend a lawsuit, arbitration, or similar legal proceeding; to respond to lawful requests by public authorities (including national security or law enforcement requirements);<br />
            i. to perform research and analysis for our business purposes; and<br />
            j. to award badges and similar designations and if you decide to participate, to administer surveys, contests, and promotions.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            If you provide us information about your contacts (for example, when you sign up for our Platform through Facebook), in order to facilitate a more connected experience, we may inform your contacts who have accounts on our Platform that you also have joined our Platform and follow them on your behalf so that you may view their public activities on our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We may use your communications generally for quality assurance purposes and training and to improve our products and services. We may use your communications to understand and help manage the quality of requests received, and responses sent, by professionals and sellers on our Platform and to provide them with feedback. We may use automated technology to review your communications and improve user experience.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We may use your log-in information, cookie information, device information, IP addresses, and other information to identify you and log your use across devices, our Platform, and third-party sites. We may use this information to personalize your experience on our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            For your convenience, we and our payment processors will store information about the payment method you use in connection with the Platform with your consent. All credit/debit cards’ details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            If you make purchases on Spacium as a visitor and subsequently create an account, for your reference, we will make information available to you about any prior purchases completed using the email address for your account.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Any use of your information that is incompatible with these uses will be disclosed to you in advance so that you may opt out of, or agree to, such uses at your discretion.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            3. Sharing Your Information
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Information about our customers is an important part of our business. The Platform may contain links to other websites as a convenience to you and other users of the Site. We make no representations or warranties as to the content, privacy practices and other practices of any websites operated by a third party and Spacium does not assume responsibility for the content or practices of such websites. The use of third-party websites will be at your own risk and it is your sole responsibility to review the terms, privacy policies and other policies for said websites.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            a. Other Businesses. To offer you our Services, we may engage with businesses who are affiliates of us and/or non-affiliated service providers (e.g. logistics businesses used to deliver products to you, marketing companies, payments processers to process online transactions, etc.). We may involve other businesses in your transactions, who may store your Information in a digital wallet to make your use of our Services more efficient.
            You understand that it is important that such businesses have access to the relevant Information to perform their functions. We will ensure that these businesses do not use your Information for other purposes. We may also receive Information from these business (e.g. updated delivery and address information), which we may use (e.g. to correct our records and deliver your next purchase). By using our Platform, you hereby freely and specifically consent to the transfer, storage, use, and disclosure of your Information among businesses who are affiliates of us and/or non-affiliated service providers, wherever located. These businesses shall be contractually bound to respect the confidentiality of your Information.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            b. Marketing and Promotional Offers. We may also use your Information to provide you with information about goods and services which may be of interest to you and enhance your Platform experience, service messages, new features, enhancements, special offers and events of interest. We may contact you via various channels, including without limitation emails, push notifications, web notifications, post, telephone, in-app messages and news feed cards.
            We may permit third parties to use your Information. For example, we may provide advertisers Information to help them reach the kind of audience they want to target and to enable us to comply with our commitments to our advertisers (e.g. by displaying their advertisements to a target audience).
            Additionally, you may be asked to provide additional Information to participate in some of our market research activities, including competitions and promotions. For example, if you win a competition, you may be asked to provide further personal data to establish your eligibility and provide you with the prize. This Information may be collected by us or our co-sponsors or vendors for the promotion. Spacium is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            c. Business Transfers. In the event that we or substantially all of our assets are acquired, customer information will be one of the transferred assets.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            d. Protection of Our Platform and Others. We release account and other Information when we believe such a release is appropriate to comply with the law and law enforcement investigations and to protect the rights, property or safety of our users or others. This includes exchanging information with other companies and organisations for various reasons, such as fraud protection and credit risk reduction.
            Note that our Platform may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data or other information to these websites.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            4. Communications
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            An essential part of our services that you sign up for when you create an account on our Platform is to receive communications from the Spacium through email and our Platform. If you use our mobile app or provide us with your cell phone number, these communications also include text messages and push notifications. We communicate with you about your account, privacy, and interactions with your content (for example, if others "like" your Ideabook, respond to your comment, or if you earn a badge on our Platform). We also ask for your feedback about your experience in connection with our Platform or about your home renovation and design plans. In accordance with applicable law, we may send you marketing communications (e.g., with tips, offers, and similar services) from the Spacium. These communications may feature us, our Platform, co-branded products and services, professionals, sellers, and other businesses on our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            If you provide us your phone number, we may call and otherwise contact you to help you with using the Platform (e.g., to discuss your home remodelling or design project, to find professionals, or to assist with your order or if you are a professional, to find products or to provide you tips about your professional profile). If you are a professional or other business, we may use your contact information (whether publicly available or provided to Spacium) to call and otherwise contact you about our Platform, including to market and sell our products or services or those available on our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            You may change your communication preferences at any time through your Spacium settings if you have a Spacium account and you may also opt-out of similar types of marketing emails from us directly through the email. To opt-out of our text messages at any time, you can follow the instructions provided in the text message, which typically is to reply “STOP.” You may opt-out of sales and marketing phone calls from us by letting us know during the call or by contacting us as described in the Contact Us section. Your communication preferences on Spacium are independent and generally will not affect or change the communications you receive through the other portions of the Platform. Please be aware that you cannot opt-out of receiving account- and privacy-related messages from us. If you wish to stop receiving push notifications from us, you can turn off push notifications for the mobile application in the settings of your mobile device.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Our Platform also allows you and others to send messages and make calls in our Platform, share content with others outside our Platform, and if you make your contact information available, for others to contact you outside of our Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            5. Your Choices and Rights
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond to your request securely and efficiently.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Even where not legally required, we will use reasonable efforts to complete requests to access or delete your personal information. You may request to receive a copy of your Spacium information through your Spacium settings. You may access both your personal information by contacting us as provided in the Contact Us section. To request permanent deletion of your personal information, please contact us as described in the Contact Us section.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            To edit your personal information, you can manage your profile on our Platform, as well as your Spacium settings. You may also contact us about correcting or updating your personal information (including your marketing preferences) using the methods provided in the Contact Us section. We will consider your request in accordance with applicable laws.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            6. Account Termination and Data Retention
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Unless you are a professional or seller on Spacium, you can deactivate your spacium account at any time by going to your Spacium settings. To deactivate your account as a professional or seller on spacium, please contact us as described in the Contact Us section of this Privacy Policy. Your Spacium profile will no longer be accessible on the Platform but in accordance with applicable law and the spacium Terms of Use, your photos, reviews, and other content may still be available through our Platform. In order to request permanent deletion of the personal information associated with your account, please contact us as described in the Contact Us section.
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We will keep your personal information only for as long as necessary to fulfil the purposes for which we are processing your personal information unless the law permits or requires us to retain your personal information for a longer period.
          </Typography>


          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            7. Children
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Our Platform is intended for general audiences and is not directed to children under 18. We do not knowingly collect personal information from children under 18. In the event that we learn that we have collected personal information from a child under age 18, we will take appropriate steps to delete that information. If you become aware or believe that a child has provided us with personal information, please contact us as provided in the Contact Us section.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            8. Data Security
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            We use reasonable and appropriate measures to protect your personal information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks involved in the processing and the nature of the personal information. Spacium takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, https://www.spacium.ae/ cannot guarantee the security of any information that is disclosed online.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            9. Updates to this Privacy Policy
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            This Privacy Policy, the Terms &amp; Conditions and other website policies may be changed or updated occasionally without notice, as per the updated and effective laws, requirements and regulations at any given time. The most current version of our Privacy Policy will govern our use of information about you. You are encouraged to frequently review these sections to be updated about the changes on the website. Modifications will be effective on the day they are posted. Your continued use of the Platform shall constitute your express consent and agreement to be bound by the Privacy Policy, Terms & Conditions and other policies. If you disagree with this Privacy Policy, you must immediately refrain from using the Platform.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            10. No Warranty
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            You understand that spacium does not provide any warranty, guarantee or representation of any kind concerning our ability to control, collect, correct, access, process, use, store, protect or transfer any personal information or concerning the existence or effectiveness of any security measures undertaken by us. You agree that spacium will not be liable for any claims, losses, or damages of any kind whatsoever which may result from the access, disclosure, use or modification by any party not authorised or authorised by us, or the introduction of viruses, worms, or other harmful elements into the system and their possible effects on your personal information.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            11. Limitation of Liability
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            In no event will spacium , its affiliates, partners, and their respective employees, officers, directors or insurers be liable to you or to any other person for any costs, damages (including any special, incidental, exemplary, indirect or consequential damages), or liability of any nature, arising or resulting from the collection, use, transfer, processing or storage of personal information obtained by us and resulting from your access to and use of the web sites/apps or collected via the other channels.
          </Typography>

          <Typography sx={{ fontFamily: 'Heavy85', fontSize: '1.1rem', mb: 2 }}>
            12. Contact Us
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            Spacium is responsible for the use of your personal information. If you have questions or comments about this Privacy Policy (including about the manner in which we or our service providers treat your personal information), you may contact us in the following email address:
          </Typography>

          <Typography sx={{ fontFamily: 'Book45', fontSize: '1rem', mb: 2 }}>
            <a style={{ color: '#006156' }} href="mailto:admin@spacium.ae"> (admin@spacium.ae)</a>
          </Typography>

        </Box>
      </Container>
    </SModal >
  )
}

export default Policy