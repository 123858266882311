import { all } from "redux-saga/effects";
import { watchRegistrationSaga } from "./registrationSaga";
import { watchLoginSaga, watchUpdatePasswordSaga } from "./loginSaga";
import {
  watchGetProductSaga,
  watchImportProductSaga,
  watchGetCategorySaga,
  watchPostProductSaga,
  watchDeleteProductSaga,
  watchUpdateProductSaga,
  watchUpdateVariantSaga,
  watchDeleteVariantSaga,
  watchCreateVariantSaga,
  watchCreateVariantImageSaga,
  watchDeleteVariantImageSaga,
  watchGetProductDetailSaga,
} from "./productSaga";
import {
  watchSellerProfileSaga,
  watchUpdateSellerProfileSaga,
} from "./sellerProfile";
import { watchGetMaterialSaga, watchGetColorSaga } from "./materialSaga";
import {
  watchGetOrderSaga,
  watchGetOrderDetailSaga,
  watchUpdateOrderSaga,
  watchUpdateOrderStatusHistorySaga,
} from "./orderSaga";
import {
  watchGetMagazineSaga,
  watchGetMagazineCategoriesSaga,
  watchPostMagazineSaga,
  watchDeleteMagazineSaga,
  watchUpdateMagazineSaga,
} from "./magazineSaga";
import { watchGetRatingSaga } from "./ratingSaga";
import { watchGetDashboardSaga } from "./dashboardSaga";
import { watchGetEarningSaga } from "./earningSaga";

function* AppSaga() {
  yield all([
    watchRegistrationSaga(),
    watchImportProductSaga(),
    watchLoginSaga(),
    watchUpdatePasswordSaga(),
    watchGetProductSaga(),
    watchSellerProfileSaga(),
    watchUpdateSellerProfileSaga(),
    watchGetMaterialSaga(),
    watchGetCategorySaga(),
    watchGetColorSaga(),
    watchGetProductDetailSaga(),
    watchPostProductSaga(),
    watchCreateVariantSaga(),
    watchCreateVariantImageSaga(),
    watchDeleteProductSaga(),
    watchDeleteVariantSaga(),
    watchDeleteVariantImageSaga(),
    watchUpdateProductSaga(),
    watchUpdateVariantSaga(),

    watchGetOrderSaga(),
    watchGetOrderDetailSaga(),
    watchUpdateOrderSaga(),
    watchUpdateOrderStatusHistorySaga(),

    watchGetMagazineSaga(),
    watchGetMagazineCategoriesSaga(),
    watchPostMagazineSaga(),
    watchUpdateMagazineSaga(),
    watchDeleteMagazineSaga(),

    watchGetRatingSaga(),

    watchGetDashboardSaga(),

    watchGetEarningSaga(),
  ]);
}

export default AppSaga;
