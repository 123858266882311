import { actionType } from "appRedux/actionType";
import Toaster from "common/Toaster";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: "",
};

export default function sellerProfile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.GET_PROFILE_METHOD:
      return { ...state, loading: true, error: "" };
    case actionType.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        res: action.payload.data,
      };
    case actionType.GET_PROFILE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.GET_PROFILE_CLEAR:
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}

export function updateSellerProfile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.UPDATE_PROFILE_METHOD:
      return { ...state, loading: true, error: "" };
    case actionType.UPDATE_PROFILE_SUCCESS:
      Toaster({ type: "success", message: "Store details updated!!" });
      return {
        ...state,
        loading: false,
        error: "",
        res: action.payload.data,
      };
    case actionType.UPDATE_PROFILE_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.UPDATE_PROFILE_CLEAR:
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}
