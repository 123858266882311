import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: ""
}

export default function postProduct(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.POST_PRODUCT_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.POST_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.POST_PRODUCT_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.POST_PRODUCT_CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        res: "",
      };

    default:
      return state;
  }
}

export function createVariant(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.CREATE_VARIANT_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.CREATE_VARIANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.CREATE_VARIANT_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.CREATE_VARIANT_CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        res: "",
      };

    default:
      return state;
  }
}

export function createVariantImage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.CREATE_IMG_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.CREATE_IMG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload.data
      };
    case actionType.CREATE_IMG_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.CREATE_IMG_CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        res: "",
      };

    default:
      return state;
  }
}