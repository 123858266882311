//MUI
import { Icon } from "@mui/material";

//Assets
import SendPng from 'assets/icons/send.png';
import LockPng from 'assets/icons/lock.png';
import UserPng from 'assets/icons/user.png';
import CallPng from 'assets/icons/call.png';
import ArrowRightPng from 'assets/icons/rightArrow.png';
import StorePng from 'assets/icons/store.png';
import QuillPng from 'assets/icons/quill.png';
import ExpandMorePng from 'assets/icons/DownArrow.png';
import LogoutPng from 'assets/icons/logout.png';
import DashboardPng from 'assets/icons/dashboard.png';
import LayoutStorePng from 'assets/icons/layoutstore.png';
import ProductPng from 'assets/icons/product.png';
import MagazinePng from 'assets/icons/magazine.png';
import RatingPng from 'assets/icons/star.png';
import OrderPng from 'assets/icons/shopping-list.png';
import WalletPng from 'assets/icons/wallet.png';
import UserSettingPng from 'assets/icons/account.png';
import DeletePng from 'assets/icons/delete.png';
import LocationPng from 'assets/icons/location.png';
import DocumentPng from 'assets/icons/document.png';
import CreditCardPng from 'assets/icons/credit-card.png';
import DeleteWhitePng from 'assets/icons/delete-white.png';
import DownloadPng from 'assets/icons/download.png'

export const SendIcon = ({ size, color }) => {
  return (
    <Icon
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%' }} src={SendPng} alt='sendpng not found' />
    </Icon>
  )
}

export const LockIcon = ({ size, color }) => {
  return (
    <Icon
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ height: '100%' }} src={LockPng} alt='lock png not found' />
    </Icon>
  )
}

export const UserIcon = ({ size, color }) => {
  return (
    <Icon
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ height: '100%' }} src={UserPng} alt='User png not found' />
    </Icon>
  )
}

export const CallIcon = ({ size, color }) => {
  return (
    <Icon
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ height: '100%' }} src={CallPng} alt='Call png not found' />
    </Icon>
  )
}

export const ArrowRightIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={ArrowRightPng} alt='Arrow right png not found' />
    </Icon>
  )
}

export const StoreIcon = ({ size, color, sx }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={StorePng} alt='Store png not found' />
    </Icon>
  )
}
export const QuillIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'medium'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={QuillPng} alt='Quill png not found' />
    </Icon>
  )
}

export const ExpandMoreIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={ExpandMorePng} alt='Quill png not found' />
    </Icon>
  )
}

export const LogoutIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={LogoutPng} alt='Quill png not found' />
    </Icon>
  )
}


export const DashboardIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DashboardPng} alt='Quill png not found' />
    </Icon>
  )
}

export const LayoutStoreIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={LayoutStorePng} alt='Quill png not found' />
    </Icon>
  )
}

export const ProductIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={ProductPng} alt='Quill png not found' />
    </Icon>
  )
}

export const MagazineIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={MagazinePng} alt='Quill png not found' />
    </Icon>
  )
}

export const RatingIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={RatingPng} alt='Quill png not found' />
    </Icon>
  )
}

export const OrderIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={OrderPng} alt='Quill png not found' />
    </Icon>
  )
}

export const WalletIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={WalletPng} alt='Quill png not found' />
    </Icon>
  )
}
export const UserAccountIcon = ({ size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex' }}
      fontSize={size ? size : 'large'}
    // color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={UserSettingPng} alt='Quill png not found' />
    </Icon>
  )
}

export const DeleteIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DeletePng} alt='Quill png not found' />
    </Icon>
  )
}

export const EllipsisIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DeletePng} alt='Quill png not found' />
    </Icon>
  )
}

export const LocationIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={LocationPng} alt='Location png not found' />
    </Icon>
  )
}

export const DocumentIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DocumentPng} alt='Location png not found' />
    </Icon>
  )
}

export const CreditCardIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={CreditCardPng} alt='Location png not found' />
    </Icon>
  )
}

export const DeleteWhiteIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DeleteWhitePng} alt='Location png not found' />
    </Icon>
  )
}

export const DownloadIcon = ({ sx, size, color }) => {
  return (
    <Icon
      sx={{ margin: 0, padding: 0, display: 'flex', ...sx }}
      fontSize={size ? size : 'large'}
      color={color ? color : 'black'}
    >
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={DownloadPng} alt='Location png not found' />
    </Icon>
  )
}