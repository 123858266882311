import React from "react";
import { useLocation } from "react-router";
import axios from "axios";
import Cookies from "js-cookie";
import ReduxStore from "../store";
import { USER_NAME, USER_TOKEN } from "./constants";
import { sellerLogin, sellerLogout } from "appRedux/actions/authAction";
import { _SERVER_DOMAIN } from "constants";

// export const BASE_URL = "http://localhost:8000/api";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const isNullEmptyUndefinedOrZero = (param) => {
  let isNEU = false;
  param = param;

  if (typeof param === "object" && !Array.isArray(param) && param !== null) {
    //if object
    if (Object.keys(param).length === 0) {
      isNEU = true;
    }
  } else {
    if (
      param === undefined ||
      param === null ||
      param === "" ||
      param === 0 ||
      param === "0" ||
      param.length === 0
    ) {
      isNEU = true;
    }
  }

  return isNEU;
};

export const getSizeFromBytes = (value) => {
  let KB = value / 1024;
  if (KB < 1024) {
    return `${Math.floor(KB)} KB`;
  } else {
    return `${Math.floor(KB / 1042)} MB`;
  }
};

export const getSizeInKb = (value) => {
  let KB = value / 1024;
  return `${Math.floor(KB)}`;
};

export const getExtensionFromFileName = (fileName) => {
  let pattern = /(?:\.([^.]+))?$/;
  return pattern.exec(fileName)[1];
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

//Get Error Message
export function getErrorMessage(data) {
  let returnError = [];
  if (typeof data === "string") return data;
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      let errorValue = "";
      for (const [key, value] of Object.entries(data[i])) {
        errorValue += value + " ";
      }
      returnError.push(errorValue + "|  ");
      errorValue = "";
    }
    return returnError;
  } else {
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          return element;
        }
      } else return value;
    }
  }
}

export const isPhoneNumberValid = (param) => {
  param = param.trim();
  const regex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/);
  return regex.test(param);
};

//Get Form Error Message
export function getFormErrorMessage(data) {
  if (data === undefined) return;
  let returnError = [];
  if (typeof data === "string") return data;
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      let errorValue = "";
      for (const [key, value] of Object.entries(data[i])) {
        errorValue += value + " ";
      }
      returnError.push(errorValue + "|  ");
      errorValue = "";
    }
    return returnError;
  } else {
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          return element;
        }
      } else return value;
    }
  }
}

//Get Form Data
export function getFormData(data) {
  let formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value) === true) {
      for (var i = 0; i < value.length; i++) {
        formData.append(`${key}`, value[i]);
      }
    } else {
      formData.append(`${key}`, value);
    }
  }
  return formData;
}

export const constructUrlWithParamsForFetch = (params) => {
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");
  return query;
};

export const sendRequest = (
  method,
  url,
  params = null,
  data = null,
  handleException = null,
  handleErrors = true
) => {
  url = params ? url + "?" + constructUrlWithParamsForFetch(params) : url;

  let customHeader = {
    "X-Requested-With": "XMLHttpRequest",
  };

  if (Cookies.get("token")) {
    let token = Cookies.get("token");
    customHeader.Authorization = `token ${token}`;
  }

  let request = axios({
    method: method,
    url: url,
    data: data,
    headers: customHeader,
    // withCredentials: true,
  }).catch((error) => {
    let errorObject = error.response?.data;

    if (errorObject) {
      if (handleException) {
        handleException(errorObject);
      } else {
        for (let [key, value] of Object.entries(errorObject)) {
          if (value instanceof Array) {
            value.forEach((item) => {
              if (item.toLowerCase() === "invalid token.") {
                // clearUserStorage();
                window.location.reload();
              } else {
                // toast.warning(item);
              }
            });
          } else {
            if (value.toLowerCase() === "invalid token.") {
              // clearUserStorage();
              window.location.reload();
            } else {
              // toast.warning(value);
            }
          }
        }
      }
    }
    // errorObject.requestStatus = _REQUESTSTATUS.error;
    // return errorObject;
    return;
  });

  return request;
};

export const loginUser = (res) => {
  Cookies.set(USER_TOKEN, res.token);
};

export const logoutUser = (navigate) => {
  Cookies.remove(USER_TOKEN);
  Cookies.remove("email");
  Cookies.remove("name");
  localStorage.removeItem(USER_NAME);
  navigate("/login", { replace: true });
};

export const changeUrlToProductionSite = (url, prefixPathname = "/seller") => {
  if (url?.startsWith("/media")) {
    let newUrl = new URL(_SERVER_DOMAIN);

    newUrl.pathname = prefixPathname + url;

    newUrl.pathname = url;
    return newUrl.href;
  }
  // console.log({ url });
  // if (!url) return "";
  // let newUrl = new URL(url);
  // newUrl.protocol = "https";
  // newUrl.hostname = "spacium.ae";
  // newUrl.pathname = prefixPathname + newUrl.pathname.replace("/media", "");
  // newUrl.port = "";
  // return newUrl.href;
  return url;
};

export function deepIterate(data, callback) {
  if (Array.isArray(data)) {
    // If the data is an array, check if it's an array of strings (errors)
    if (data.every((item) => typeof item === "string")) {
      // If it's an array of strings, pass the entire array to the callback
      callback(data);
    } else {
      // Otherwise, iterate over each item in the array
      data.forEach((item) => deepIterate(item, callback));
    }
  } else if (typeof data === "object" && data !== null) {
    // If the data is an object, iterate over each property
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        deepIterate(data[key], callback);
      }
    }
  } else {
    // For non-object/array types (e.g., individual values), call the callback on the value
    callback(data);
  }
}
