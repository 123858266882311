import Loading from "common/loader";

const CustomLoading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "rgba(0,0,0,.15)",
        }}
      >
        <Loading />
      </div>
    );
  };


  export default CustomLoading;