export const actionType = {
  // ----- Registration -----
  SELLER_REGISTER_METHOD: "seller_register_method",
  SELLER_REGISTER_SUCCESS: "seller_register_success",
  SELLER_REGISTER_FAILURE: "seller_register_failure",
  SELLER_REGISTER_CLEAR: "seller_register_clear",

  // ----- Login -----
  SELLER_LOGIN_METHOD: "seller_login_method",
  SELLER_LOGIN_SUCCESS: "seller_login_success",
  SELLER_LOGIN_FAILURE: "seller_login_failure",
  SELLER_LOGIN_CLEAR: "seller_login_clear",

  // ----- Logout -----
  SELLER_LOGOUT_METHOD: "SELLER_LOGOUT_METHOD",

  // ----- Change Password -----
  UPDATE_PASSWORD_METHOD: "update_password_method",
  UPDATE_PASSWORD_SUCCESS: "update_password_success",
  UPDATE_PASSWORD_FAILURE: "update_password_failure",
  UPDATE_PASSWORD_CLEAR: "update_password_clear",

  // ----- Product -----
  GET_PRODUCT_METHOD: "get_product_method",
  GET_PRODUCT_SUCCESS: "get_product_success",
  GET_PRODUCT_FAILURE: "get_product_failure",
  GET_PRODUCT_CLEAR: "get_product_clear",

  // ----- Product -----
  GET_PRODUCT_DETAIL_METHOD: "get_product_detail_method",
  GET_PRODUCT_DETAIL_SUCCESS: "get_product_detail_success",
  GET_PRODUCT_DETAIL_FAILURE: "get_product_detail_failure",
  GET_PRODUCT_DETAIL_CLEAR: "get_product_detail_clear",

  // ----- Product -----
  POST_PRODUCT_METHOD: "post_product_method",
  POST_PRODUCT_SUCCESS: "post_product_success",
  POST_PRODUCT_FAILURE: "post_product_failure",
  POST_PRODUCT_CLEAR: "post_product_clear",

  // ------- Post Product -----
  IMPORT_PRODUCT_METHOD: "import_product_method",
  IMPORT_PRODUCT_SUCCESS: "import_product_success",
  IMPORT_PRODUCT_FAILURE: "import_product_failure",
  IMPORT_PRODUCT_CLEAR: "import_product_clear",

  // ------- Delete Product -----
  DEL_PRODUCT_METHOD: "del_product_method",
  DEL_PRODUCT_SUCCESS: "del_product_success",
  DEL_PRODUCT_FAILURE: "del_product_failure",
  DEL_PRODUCT_CLEAR: "del_product_clear",

  // ------- Update Product -----
  UPDATE_PRODUCT_METHOD: "update_product_method",
  UPDATE_PRODUCT_SUCCESS: "update_product_success",
  UPDATE_PRODUCT_FAILURE: "update_product_failure",
  UPDATE_PRODUCT_CLEAR: "update_product_clear",

  // ------- Create Variant -----
  CREATE_VARIANT_METHOD: "create_variant_method",
  CREATE_VARIANT_SUCCESS: "create_variant_success",
  CREATE_VARIANT_FAILURE: "create_variant_failure",
  CREATE_VARIANT_CLEAR: "create_variant_clear",

  // ------- Update Variant -----
  UPDATE_VARIANT_METHOD: "update_variant_method",
  UPDATE_VARIANT_SUCCESS: "update_variant_success",
  UPDATE_VARIANT_FAILURE: "update_variant_failure",
  UPDATE_VARIANT_CLEAR: "update_variant_clear",

  // ------- Delete Variant -----
  DEL_VARIANT_METHOD: "del_variant_method",
  DEL_VARIANT_SUCCESS: "del_variant_success",
  DEL_VARIANT_FAILURE: "del_variant_failure",
  DEL_VARIANT_CLEAR: "del_variant_clear",

  // ------- Create Variant Image -----
  CREATE_IMG_METHOD: "create_IMG_method",
  CREATE_IMG_SUCCESS: "create_IMG_success",
  CREATE_IMG_FAILURE: "create_IMG_failure",
  CREATE_IMG_CLEAR: "create_IMG_clear",

  // ------- Delete Variant Image-----
  DEL_IMG_METHOD: "del_img_method",
  DEL_IMG_SUCCESS: "del_img_success",
  DEL_IMG_FAILURE: "del_img_failure",
  DEL_IMG_CLEAR: "del_img_clear",

  // ------- Get Order -----
  GET_ORDER_METHOD: "get_order_method",
  GET_ORDER_SUCCESS: "get_order_success",
  GET_ORDER_FAILURE: "get_order_failure",
  GET_ORDER_CLEAR: "get_order_clear",

  // ------- Update Order -----
  UPDATE_ORDER_METHOD: "update_order_method",
  UPDATE_ORDER_SUCCESS: "update_order_success",
  UPDATE_ORDER_FAILURE: "update_order_failure",
  UPDATE_ORDER_CLEAR: "update_order_clear",

  // ------- Update Order Status History -----
  UPDATE_ORDER_STATUS_HISTORY_METHOD: "update_order_status_history_method",
  UPDATE_ORDER_STATUS_HISTORY_SUCCESS: "update_order_status_history_success",
  UPDATE_ORDER_STATUS_HISTORY_FAILURE: "update_order_status_history_failure",
  UPDATE_ORDER_STATUS_HISTORY_CLEAR: "update_order_status_history_clear",

  // ------- Get Order -----
  GET_ORDER_DETAIL_METHOD: "get_order_detail_method",
  GET_ORDER_DETAIL_SUCCESS: "get_order_detail_success",
  GET_ORDER_DETAIL_FAILURE: "get_order_detail_failure",
  GET_ORDER_DETAIL_CLEAR: "get_order_detail_clear",

  // ----- Store Profile -----
  GET_PROFILE_METHOD: "get_profile_method",
  GET_PROFILE_SUCCESS: "get_profile_success",
  GET_PROFILE_FAILURE: "get_profile_failure",
  GET_PROFILE_CLEAR: "get_profile_clear",

  // ----- Store Profile -----
  UPDATE_PROFILE_METHOD: "update_profile_method",
  UPDATE_PROFILE_SUCCESS: "update_profile_success",
  UPDATE_PROFILE_FAILURE: "update_profile_failure",
  UPDATE_PROFILE_CLEAR: "update_profile_clear",

  // ----- Product Material -----
  GET_MATERIAL_METHOD: "get_material_method",
  GET_MATERIAL_SUCCESS: "get_material_success",
  GET_MATERIAL_FAILURE: "get_material_failure",
  GET_MATERIAL_CLEAR: "get_material_clear",

  // ----- Product Color -----
  GET_COLOR_METHOD: "get_color_method",
  GET_COLOR_SUCCESS: "get_color_success",
  GET_COLOR_FAILURE: "get_color_failure",
  GET_COLOR_CLEAR: "get_color_clear",

  // ----- Product Category -----
  GET_CATEGORY_METHOD: "get_category_method",
  GET_CATEGORY_SUCCESS: "get_category_success",
  GET_CATEGORY_FAILURE: "get_category_failure",
  GET_CATEGORY_CLEAR: "get_category_clear",

  // ----- Magazine -----
  GET_MAGAZINE_METHOD: "get_magazine_method",
  GET_MAGAZINE_SUCCESS: "get_magazine_success",
  GET_MAGAZINE_FAILURE: "get_magazine_failure",
  GET_MAGAZINE_CLEAR: "get_magazine_clear",

  // ----- Rating -----
  GET_RATING_METHOD: "get_rating_method",
  GET_RATING_SUCCESS: "get_rating_success",
  GET_RATING_FAILURE: "get_rating_failure",
  GET_RATING_CLEAR: "get_rating_clear",

  // ----- Magazine -----
  GET_MAGAZINE_CATEGORY_METHOD: "get_magazine_category_method",
  GET_MAGAZINE_CATEGORY_SUCCESS: "get_magazine_category_success",
  GET_MAGAZINE_CATEGORY_FAILURE: "get_magazine_category_failure",
  GET_MAGAZINE_CATEGORY_CLEAR: "get_magazine_category_clear",

  // ----- Create Magazine -----
  POST_MAGAZINE_METHOD: "post_magazine_method",
  POST_MAGAZINE_SUCCESS: "post_magazine_success",
  POST_MAGAZINE_FAILURE: "post_magazine_failure",
  POST_MAGAZINE_CLEAR: "post_magazine_clear",

  // ------- Delete Magazine -----
  DEL_MAGAZINE_METHOD: "del_magazine_method",
  DEL_MAGAZINE_SUCCESS: "del_magazine_success",
  DEL_MAGAZINE_FAILURE: "del_magazine_failure",
  DEL_MAGAZINE_CLEAR: "del_magazine_clear",

  // ------- Update Magazine -----
  UPDATE_MAGAZINE_METHOD: "update_magazine_method",
  UPDATE_MAGAZINE_SUCCESS: "update_magazine_success",
  UPDATE_MAGAZINE_FAILURE: "update_magazine_failure",
  UPDATE_MAGAZINE_CLEAR: "update_magazine_clear",

  // ----- dashboard -----
  GET_DASHBOARD_METHOD: "get_dashboard_method",
  GET_DASHBOARD_SUCCESS: "get_dashboard_success",
  GET_DASHBOARD_FAILURE: "get_dashboard_failure",
  GET_DASHBOARD_CLEAR: "get_dashboard_clear",

  TOGGLE_DRAWER_METHOD: "toggle_drawer_method",

  // ----- Earning -----
  GET_EARNING_METHOD: "get_earning_method",
  GET_EARNING_SUCCESS: "get_earning_success",
  GET_EARNING_FAILURE: "get_earning_failure",
  GET_EARNING_CLEAR: "get_earning_clear",
};
