import { put } from 'redux-saga/effects';
import { API_REGISTER } from 'appRedux/apiType';
import { actionType } from 'appRedux/actionType';
import apiCall from 'appRedux/apiCall';
import { takeEvery, takeLatest } from 'redux-saga/effects';

// ----- Registration Watcher -----
export function* watchRegistrationSaga() {
  yield takeEvery(actionType.SELLER_REGISTER_METHOD, registrationSaga)
}

// ----- Saga -----
function* registrationSaga(action) {
  try {
    let url = {
      type: API_REGISTER,
      payload: action.payload,
      apiType: 'POST'
    }
    const res = yield apiCall(url);
    yield put({ type: actionType.SELLER_REGISTER_SUCCESS, payload: res })
  }
  catch (error) {
    yield put({ type: actionType.SELLER_REGISTER_FAILURE, payload: error })
  }
}