import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: ""
}

export default function registration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.SELLER_REGISTER_METHOD:
      return { ...state, loading: true, error: '' };
    case actionType.SELLER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        res: action.payload
      };
    case actionType.SELLER_REGISTER_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };

    default:
      return state;
  }
}