import { put } from 'redux-saga/effects';
import { API_COLOR, API_MATERIAL } from 'appRedux/apiType';
import { actionType } from 'appRedux/actionType';
import apiCall from 'appRedux/apiCall';
import { takeLatest } from 'redux-saga/effects';

// ----- Get Product Watcher -----
export function* watchGetMaterialSaga() {
  yield takeLatest(actionType.GET_MATERIAL_METHOD, getMaterialSaga)
}

export function* watchGetColorSaga() {
  yield takeLatest(actionType.GET_COLOR_METHOD, getColorSaga)
}

// ----- Saga -----
function* getMaterialSaga(action) {
  try {
    let url = {
      type: API_MATERIAL,
      payload: action.payload,
      apiType: 'GET'
    }
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_MATERIAL_SUCCESS, payload: res })
  }
  catch (error) {
    yield put({ type: actionType.GET_MATERIAL_FAILURE, payload: error })
  }
}


function* getColorSaga(action) {
  try {
    let url = {
      type: API_COLOR,
      payload: action.payload,
      apiType: 'GET'
    }
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_COLOR_SUCCESS, payload: res })
  }
  catch (error) {
    yield put({ type: actionType.GET_COLOR_FAILURE, payload: error })
  }
}
