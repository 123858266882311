import { put } from "redux-saga/effects";
import { API_EARNING } from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";
import { takeLatest, takeEvery } from "redux-saga/effects";

export function* watchGetEarningSaga() {
  yield takeLatest(actionType.GET_EARNING_METHOD, getEarningSaga);
}

function* getEarningSaga(action) {
  try {
    let url = {
      type: API_EARNING,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_EARNING_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_EARNING_FAILURE, payload: error });
  }
}
