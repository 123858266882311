import { Box, CircularProgress, Typography } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import {
  API_ACCEPT_SELLER_AGREEMENT,
  API_GET_SELLER_AGREEMENT,
} from "appRedux/apiType";
import SButton from "common/material/panel/SButton";
import SInput from "common/material/panel/SInput";
import Toaster from "common/Toaster";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "utils";

const SellerAgreement = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [NDA, setNDA] = useState("");
  const [name, setName] = useState("");
  const { profileLoading, profileError, profileRes } = useSelector((state) => ({
    profileLoading: state.sellerProfile.loading,
    profileError: state.sellerProfile.error,
    profileRes: state.sellerProfile.res,
  }));

  const getSellerAgreement = () => {
    const apiData = {
      type: API_GET_SELLER_AGREEMENT,
      apiType: "GET",
    };
    setIsLoading(true);
    apiCallV2(apiData)
      .then((res) => setNDA(res.data))
      .catch((err) => Toaster({ type: "error", message: getErrorMessage(err) }))
      .finally(() => setIsLoading(false));
  };

  const acceptSellerAgreement = () => {
    const apiPayload = {
      seller_agreement_sign: name,
    };

    const apiData = {
      type: API_ACCEPT_SELLER_AGREEMENT,
      apiType: "POST",
      payload: apiPayload,
    };

    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) =>
        Toaster({ type: "error", message: getErrorMessage(err) })
      );
  };

  const handleSetData = () => {
    setName(profileRes.seller_agreement_sign || "");
  };

  useEffect(() => {
    if (profileRes) handleSetData();
  }, [profileRes]);

  useEffect(() => {
    getSellerAgreement();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <iframe
          srcDoc={NDA.replace(/(<? *script)/gi, "illegalscript")}
          width="100%"
          height="1000px"
          style={{
            maxWidth: "900px",
            alignSelf: "center",
          }}
          title="Seller Agreement"
        ></iframe>
        // <div
        //   dangerouslySetInnerHTML={{
        //     __html: NDA.replace(/(<? *script)/gi, "illegalscript"),
        //   }}
        // ></div>
      )}
      {profileRes?.seller_agreement_date ? (
        <Typography sx={{ fontFamily: "bold" }}>
          Agreement digitally accepted on{" "}
          {moment(profileRes?.seller_agreement_date).format("DD-MM-YYYY")} by "
          {profileRes?.seller_agreement_sign}"
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SInput
            label={"Name on Seller Agreement"}
            sx={{ width: "60%" }}
            placeholder={t("name_of_authorized_representative_placeholder")}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <SButton
            onClick={acceptSellerAgreement}
            variant="contained"
            mySx={{ borderRadius: "25px", width: "140px", ml: 4 }}
            disabled={profileRes?.seller_agreement_date}
          >
            {t("accept")}
          </SButton>
        </Box>
      )}
    </Box>
  );
};

export default SellerAgreement;
