import { Close } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import { API_STORE } from "appRedux/apiType";
import DropImg from "assets/drop-img-icon.png";
import SButton from "common/material/panel/SButton";
import SInput from "common/material/panel/SInput";
import Toaster from "common/Toaster";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeUrlToProductionSite, getErrorMessage } from "utils";

const Documents = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();
  const [nationalId, setNationalId] = useState("");
  const [tradeLicence, setTradeLicence] = useState("");
  const [VAT, setVAT] = useState("");
  const [emiratedFront, setEmiratedFront] = useState("");
  const [emiratesBack, setEmiratesBack] = useState("");
  const [tradeLicenceNumber, setTradeLicenceNumber] = useState("");
  console.log(nationalId);

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const disabled = !!profileRes?.seller_agreement_date;

  useEffect(() => {
    if (profileRes) {
      handleSetData();
    }
  }, [profileRes]);

  const handleSetData = () => {
    setNationalId(changeUrlToProductionSite(profileRes.passport));
    setEmiratedFront(changeUrlToProductionSite(profileRes.uae_id_front));
    setEmiratesBack(changeUrlToProductionSite(profileRes.uae_id_back));
    setVAT(changeUrlToProductionSite(profileRes.vat_document));
    setTradeLicence(changeUrlToProductionSite(profileRes.trade_license));
    setTradeLicenceNumber(
      changeUrlToProductionSite(profileRes.trade_license_no)
    );
  };

  const save = () => {
    const apiPayload = {
      uae_id_front: emiratedFront,
      uae_id_back: emiratesBack,
      trade_license: tradeLicence,
      vat_document: VAT,
      passport: nationalId,
      trade_license_no: tradeLicenceNumber,
    };
    console.log({ apiPayload });
    if (emiratedFront == profileRes?.uae_id_front)
      delete apiPayload.uae_id_front;
    if (emiratesBack == profileRes?.uae_id_back) delete apiPayload.uae_id_back;
    if (tradeLicence == profileRes?.trade_license)
      delete apiPayload.trade_license;
    if (VAT == profileRes?.vat_document) delete apiPayload.vat_document;
    if (nationalId == profileRes?.passport) delete apiPayload.passport;
    if (tradeLicenceNumber == profileRes?.trade_license_number)
      delete apiPayload.trade_license_no;

    const apiData = {
      type: `${API_STORE}add-store-documents/`,
      apiType: "POST",
      payload: apiPayload,
    };
    if (profileRes && profileRes.id) {
      apiData.type = `${API_STORE}${profileRes.id}/store-documents/`;
      apiData.apiType = "PATCH";
    }
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  const RenderDocCard = ({ count, image, setImage, title }) => {
    return (
      <Grid sx={{ mb: 2, pr: 3, display: "flex" }} item xs={12} md={6}>
        <Box sx={{ width: "30px" }}>
          <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
            {count}.
          </Typography>
        </Box>
        <FormControl
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                fontFamily: "Book45",
                fontSize: "1.1rem",
                border: "none",
              },
            },
          }}
        >
          <FormLabel
            component="legend"
            sx={{
              color: "#000",
              fontFamily: "Roman55",
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            {title}
            <Tooltip title={title} placement="right">
              <Box
                component="span"
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "21px",
                  width: "21px",
                  padding: "2px",
                  ml: 1,
                  background: "#707070",
                  borderRadius: "50%",
                  color: "#fff",
                }}
              >
                ?
              </Box>
            </Tooltip>
          </FormLabel>
          {image ? (
            (
              typeof image === "string"
                ? image?.includes("pdf")
                : image?.name?.includes("pdf")
            ) ? (
              <Box
                sx={{
                  boxShadow: "0px 3px 6px rgba(0,97,86,.05)",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  py: 2,
                  px: 3,
                }}
              >
                <Typography>{image?.name}</Typography>

                <IconButton
                  sx={{
                    width: "25px",
                    height: "25px",
                    background: "rgba(0,0,0,.2)",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                >
                  <Close
                    fontSize="small"
                    sx={{ color: "#fff" }}
                    onClick={(e) => setImage("")}
                  />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  boxShadow: "0px 3px 6px rgba(0,97,86,.05)",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  py: 2,
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    height: "130px",
                    border: "2px solid black",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                    src={
                      typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    }
                    aalt="not found"
                  />
                </Box>
                <IconButton
                  sx={{
                    width: "25px",
                    height: "25px",
                    background: "rgba(0,0,0,.2)",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                >
                  <Close
                    fontSize="small"
                    sx={{ color: "#fff" }}
                    onClick={(e) => setImage("")}
                  />
                </IconButton>
              </Box>
            )
          ) : (
            <FormControl sx={{ width: "100%" }}>
              <FileUploader
                name="file"
                types={["jpg", "png", "jpeg", "pdf"]}
                maxSize={0.4}
                onSizeError={(file) => {
                  Toaster({
                    type: "error",
                    message: "File should not be more than 400 KB",
                  });
                }}
                onDrop={(file) => {
                  setImage(file);
                }}
                onSelect={(file) => {
                  setImage(file);
                }}
                children={
                  <Box
                    sx={{
                      border: "2px dashed #F3F3F3",
                      position: "relative",
                      borderRadius: "10px",
                      width: "100%",
                      height: "170px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={DropImg} alt="not found" />
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Book45",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      Drag your image of MAX 400KB here,
                      <br />
                      or{" "}
                      <Box
                        component="span"
                        sx={{
                          fontFamily: "Heavy85",
                          color: "#006156",
                          textDecoration: "underline",
                        }}
                      >
                        Browse
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormControl>
          )}
        </FormControl>
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container sx={{ mb: 2 }}>
        <RenderDocCard
          count={"1"}
          title={t("passport")}
          image={nationalId}
          setImage={(image) => !disabled && setNationalId(image)}
        />
        <RenderDocCard
          count={"2"}
          title={t("trade_licence")}
          image={tradeLicence}
          setImage={(image) => !disabled && setTradeLicence(image)}
        />
        <RenderDocCard
          count={"3"}
          title={t("VAT")}
          image={VAT}
          setImage={(image) => !disabled && setVAT(image)}
        />
        <RenderDocCard
          count={"4"}
          title={t("emirates_id_front")}
          image={emiratedFront}
          setImage={(image) => !disabled && setEmiratedFront(image)}
        />
        <RenderDocCard
          count={"4"}
          title={t("emirates_id_back")}
          image={emiratesBack}
          setImage={(image) => !disabled && setEmiratesBack(image)}
        />
        <Grid xs={12} mt={2}>
          <SInput
            label={t("trade_license_number")}
            sx={{ width: "100%" }}
            placeholder={t("trade_license_number")}
            value={tradeLicenceNumber}
            disabled={disabled}
            onChange={(e) => {
              setTradeLicenceNumber(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SButton
            mySx={{ borderRadius: "25px", width: "140px" }}
            disabled={disabled}
          >
            {t("cancel")}
          </SButton>
          <SButton
            variant="contained"
            mySx={{ borderRadius: "25px", width: "140px", ml: 4 }}
            disabled={disabled}
            onClick={(e) => save()}
          >
            {t("save")}
          </SButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default Documents;
