import { parseCookies } from "nookies";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Layout from "../layout";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { logoutUser } from "utils";

const ProtectedRoutes = () => {
  const { token } = parseCookies();
  const navigate = useNavigate();
  const { profileLoading, profileError, profileRes } = useSelector(
    (state) => ({
      profileLoading: state.sellerProfile.loading,
      profileError: state.sellerProfile.error,
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const isPhoneVerified =
    (profileLoading !== false && !profileError) ||
    profileRes?.manager?.is_phone_verified == 1;
  useEffect(() => {
    if (profileError?.status === 401) {
      logoutUser(navigate);
    }
  }, [profileError]);

  if (!isPhoneVerified) {
    return <Navigate to="/mobile-verification" />;
  }
  return token ? (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
