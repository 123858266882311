import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  isDrawerOpen: false
}

export default function drawerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.TOGGLE_DRAWER_METHOD:
      return { ...state, isDrawerOpen: action.payload.open }; 
    default:
      return state;
  }
}

