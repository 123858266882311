import { put } from 'redux-saga/effects';
import { API_DASHBARD } from 'appRedux/apiType';
import { actionType } from 'appRedux/actionType';
import apiCall from 'appRedux/apiCall';
import { takeLatest, takeEvery } from 'redux-saga/effects';

export function* watchGetDashboardSaga() {
  yield takeLatest(actionType.GET_DASHBOARD_METHOD, getDashboardSaga)
}


function* getDashboardSaga(action) {
    try {
      let url = {
        type: API_DASHBARD,
        payload: action.payload,
        apiType: 'GET'
      }
      const res = yield apiCall(url);
      yield put({ type: actionType.GET_DASHBOARD_SUCCESS, payload: res })
    }
    catch (error) {
      yield put({ type: actionType.GET_DASHBOARD_FAILURE, payload: error })
    }
  }

