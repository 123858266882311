import { combineReducers } from "redux";

import registration from "./registration";
import login, { updatePassword } from "./login";
import getProduct from "./getProduct";
import sellerProfile, { updateSellerProfile } from "./sellerProfile";
import getMaterial, { getColor } from "./getMaterial";
import getCategory from "./category";
import postProduct, { createVariant, createVariantImage } from "./saveProduct";
import importProduct from "./importProduct";
import deleteProduct, {
  deleteVariant,
  deleteVariantImage,
} from "./deleteProduct";
import updateProduct, { updateVariant } from "./updateProduct";
import getOrder, {
  getOrderDetail,
  updateOrder,
  updateOrderStatusHistory,
} from "./orderReducer";
import getMagazine, {
  getMagazineCategories,
  createMagazine,
  updateMagazine,
  deleteMagazine,
} from "./magazineReducer";
import getRating from "./ratingReducer";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import drawerReducer from "./drawerReducer";
import earningReducer from "./earningReducer";
import getProductDetail from "./getProductDetail";

const AppReducer = combineReducers({
  registration,
  login,
  updatePassword,

  sellerProfile,
  updateSellerProfile,

  getMaterial,
  getCategory,
  getColor,

  getProduct,
  getProductDetail,
  importProduct,

  postProduct,
  createVariant,
  createVariantImage,

  deleteProduct,
  deleteVariant,
  deleteVariantImage,

  updateProduct,
  updateVariant,

  getOrder,
  getOrderDetail,
  updateOrder,
  updateOrderStatusHistory,

  getMagazine,
  getMagazineCategories,
  createMagazine,
  updateMagazine,
  deleteMagazine,

  getRating,
  authReducer,
  dashboardReducer,
  drawerReducer,

  earningReducer,
});

export default AppReducer;
