import { Box, InputAdornment, Typography } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import { API_STORE } from "appRedux/apiType";
import SButton from "common/material/panel/SButton";
import SInput from "common/material/panel/SInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "utils";

const GeneralDetails = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();
  const [storeName, setStoreName] = useState();
  const [description, setDescription] = useState();

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );
  const disabled = !!profileRes?.seller_agreement_date;

  const save = () => {
    const apiPayload = {
      store_name: storeName,
      description: description,
    };
    const apiData = {
      type: `${API_STORE}${profileRes.id}/manage-store/`,
      apiType: "PATCH",
      payload: apiPayload,
    };
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  const handleSetData = () => {
    setStoreName(profileRes.store_name || "");
    setDescription(profileRes.description || "");
  };

  useEffect(() => {
    if (profileRes) handleSetData();
  }, [profileRes]);

  return (
    <Box>
      <Box sx={{ mb: 2, width: "100%" }}>
        <Box sx={{ mb: 2, pr: 3, display: "flex" }}>
          <Box sx={{ width: "30px" }}>
            <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
              1.
            </Typography>
          </Box>
          <SInput
            label={t("store_name")}
            value={storeName}
            disabled={disabled}
            onChange={(e) => {
              setStoreName(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ pr: 3, display: "flex" }}>
          <Box sx={{ width: "30px" }}>
            <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
              2.
            </Typography>
          </Box>
          <SInput
            editor
            label={t("store_description")}
            value={description}
            multiline={true}
            rows={6}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ transform: "translate(0px, 60px)" }}
                >
                  <Typography
                    variant="subtitle3"
                    sx={{ fontSize: "15px" }}
                  >{`120/150`}</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              console.log(e);
              if (typeof e === "string") return setDescription(e);

              setDescription(e.target.value);
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SButton mySx={{ borderRadius: "25px", width: "140px" }}>
          {t("cancel")}
        </SButton>
        <SButton
          variant="contained"
          mySx={{ borderRadius: "25px", width: "140px", ml: 4 }}
          onClick={(e) => save()}
        >
          {t("save")}
        </SButton>
      </Box>
    </Box>
  );
};

export default GeneralDetails;
