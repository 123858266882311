import { Box, Typography } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import { API_STORE } from "appRedux/apiType";
import SButton from "common/material/panel/SButton";
import SInput from "common/material/panel/SInput";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "utils";

const Declaration = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const save = () => {
    const date = moment().format("YYYY-MM-DD");
    const apiPayload = {
      authorized_person: name,
      declaration_date: date,
    };

    const apiData = {
      type: `${API_STORE}${profileRes.id}/manage-store/`,
      apiType: "PATCH",
      payload: apiPayload,
    };
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  const handleSetData = () => {
    setName(profileRes.authorized_person);
  };

  useEffect(() => {
    if (profileRes) {
      handleSetData();
    }
  }, [profileRes]);

  return (
    <Box>
      <Box sx={{ mb: 2, pr: 3 }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
            1. &nbsp;
          </Typography>
          <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
            {t("declaration_authorized")}
          </Typography>
        </Box>
        <Typography my={4}>{t("declaration_authorized_subtitle")}</Typography>
        {profileRes?.seller_agreement_date ? (
          <Typography sx={{ fontWeight: "bold" }}>
            Declaration Accepted on{" "}
            {moment(profileRes?.declaration_date).format("DD-MM-YYYY")} by{" "}
            {profileRes?.authorized_person}
          </Typography>
        ) : (
          <>
            <SInput
              label={t("name_of_authorized_representative")}
              sx={{ width: "60%" }}
              placeholder={t("name_of_authorized_representative_placeholder")}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <SButton
                variant="contained"
                mySx={{ borderRadius: "25px", width: "140px" }}
                onClick={(e) => save()}
              >
                {t("accept")}
              </SButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Declaration;
