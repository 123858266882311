import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  DashboardIcon,
  LayoutStoreIcon,
  MagazineIcon,
  OrderIcon,
  ProductIcon,
  RatingIcon,
  UserAccountIcon,
  WalletIcon,
} from "common/icons";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    fontFamily: "Roman55",
    height: "50px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "30px !important",
    minWidth: "30px !important",
    height: "30px",
    background: "#E5EFEE",
    borderRadius: "5px",
  },
  linkColor: {
    width: "100%",
    color: "#fff",
    cursor: "pointer",
  },
  linkText: {
    marginLeft: "20px",
  },
  activeLink: {
    width: "100%",
    background: "#fff",
    color: "#006156",
    borderLeft: "10px solid #006156",
    transition: ".3s ease all",
    cursor: "pointer",
  },
});

const linkData = [
  {
    name: "Dashboard",
    label: "Dashboard",
    link: "/dashboard",
    exact: true,
    icon: <DashboardIcon size="small" />,
  },
  {
    name: "Store Management",
    label: "Store Management",
    link: "/store",
    exact: true,
    icon: <LayoutStoreIcon size="small" />,
  },
  {
    name: "Product Mangement",
    label: "Product Mangement",
    link: "/products",
    exact: true,
    icon: <ProductIcon size="small" />,
  },
  {
    name: "Magazine Mangement",
    label: "Magazine Mangement",
    link: "/magazines",
    exact: true,
    icon: <MagazineIcon size="small" />,
  },
  {
    name: "Review And Ratings",
    label: "Review And Ratings",
    link: "/rating",
    exact: true,
    icon: <RatingIcon size="small" />,
  },
  {
    name: "Orders Mangement",
    label: "Orders Mangement",
    link: "/orders",
    exact: true,
    icon: <OrderIcon size="small" />,
  },
  {
    name: "My Earning",
    label: "My Earning",
    link: "/earning",
    exact: true,
    icon: <WalletIcon size="small" />,
  },
  {
    name: "My Account",
    label: "My Account",
    link: "/account",
    exact: true,
    icon: <UserAccountIcon size="small" />,
  },
];

const SpaciumLink = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const navigate = useNavigate();

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const handleRedirect = (link) => {
    navigate(link);
    if (profileRes?.is_verified) {
    }
  };

  return linkData.map((item, idx) => {
    return (
      <List key={`${item.name}_${idx}`} className={classes.root}>
        <Box
          onClick={() => handleRedirect(item.link)}
          className={
            location.pathname == item.link
              ? classes.activeLink
              : classes.linkColor
          }
        >
          <ListItem className="heooooo">
            <ListItemIcon className={classes.iconHolder}>
              {item.icon}
            </ListItemIcon>
            <ListItemText className={classes.linkText}>
              {item.label}
            </ListItemText>
          </ListItem>
        </Box>
      </List>
    );
  });
};

export default SpaciumLink;
{
  /* <NavLink
          exact={item.exact.toString()}
          to={profileRes?.is_verified ? item.link : "/store"}
          style={{
            textDecoration: "none",
          }}
          className={(navData) =>
            navData.isActive ? classes.activeLink : classes.linkColor
          }
        >
          <ListItem className="heooooo">
            <ListItemIcon className={classes.iconHolder}>
              {item.icon}
            </ListItemIcon>
            <ListItemText className={classes.linkText}>
              {item.label}
            </ListItemText>
          </ListItem>
        </NavLink> */
}
