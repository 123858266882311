import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { API_CHANGE_PASS, API_LOGIN } from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";
import apiCallV2 from "appRedux/apiCallV2";

// ----- Login Watcher -----
export function* watchLoginSaga() {
  yield takeLatest(actionType.SELLER_LOGIN_METHOD, loginSaga);
}

export function* watchUpdatePasswordSaga() {
  yield takeEvery(actionType.UPDATE_PASSWORD_METHOD, updatePasswordSaga);
}

// ----- Saga -----
function* loginSaga(action) {
  try {
    let url = {
      type: API_LOGIN,
      payload: action.payload,
      apiType: "POST",
    };
    const res = yield apiCallV2(url);
    yield put({ type: actionType.SELLER_LOGIN_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.SELLER_LOGIN_FAILURE, payload: error });
  }
}

function* updatePasswordSaga(action) {
  try {
    let url = {
      type: API_CHANGE_PASS,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.UPDATE_PASSWORD_SUCCESS, payload: res });
    yield put({ type: actionType.UPDATE_PASSWORD_CLEAR, payload: "" });
  } catch (error) {
    yield put({ type: actionType.UPDATE_PASSWORD_FAILURE, payload: error });
  }
}
