import { Box } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import Policy from "component/agreement/Policy";
import Terms from "component/agreement/Terms";
import { parseCookies } from "nookies";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const { token } = parseCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [termsOpen, setTermsOpen] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);

  const { isUserLoggedIn, profileLoading, profileError, profileRes } =
    useSelector(
      (state) => ({
        profileLoading: state.sellerProfile.loading,
        profileError: state.sellerProfile.error,
        profileRes: state.sellerProfile.res,
        isUserLoggedIn: state.authReducer.isUserLoggedIn,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (token) {
      dispatch(getSellerProfile());
    }
  }, [token]);

  // useEffect(() => {
  //   if (profileRes && profileRes.id) {
  //     if (profileRes?.manager?.is_phone_verified == false) {
  //       navigate("/mobile-verification");
  //     } else if (profileRes?.is_verified == false) {
  //       navigate("/store");
  //     } else {
  //       navigate("/dashboard");
  //     }
  //   }
  // }, [profileRes]);

  const handleTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);

  const handlePolicyOpen = () => setPolicyOpen(true);
  const handlePolicyClose = () => setPolicyOpen(false);

  return (
    <Box sx={{ display: "flex" }}>
      <Box>
        <Sidebar
          handleTermsOpen={handleTermsOpen}
          handlePolicyOpen={handlePolicyOpen}
        />
      </Box>
      <Box sx={{ width: "100%", overflow: "hidden" }}>{children}</Box>
      <Terms open={termsOpen} handleClose={handleTermsClose} />
      <Policy open={policyOpen} handleClose={handlePolicyClose} />
    </Box>
  );
};

export default React.memo(Layout);
