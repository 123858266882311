import TopBar from "layout/TopBar";
import { useEffect } from "react";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Box } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import {
  CreditCardIcon,
  DocumentIcon,
  LocationIcon,
  StoreIcon,
} from "common/icons";
import SAccordion from "common/material/panel/SAccordion";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isNullEmptyUndefinedOrZero } from "utils";
import Address from "./Address";
import BankDetails from "./BankDetails";
import Branding from "./Branding";
import Declaration from "./Declaration";
import Documents from "./Documents";
import GeneralDetails from "./GeneralDetails";
import SellerAgreement from "./SellerAgreement";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Store = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("store");

  const { loading, errors, res } = useSelector(
    (state) => ({
      loading: state.sellerProfile.loading,
      errors: state.sellerProfile.error,
      res: state.sellerProfile.res,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (isNullEmptyUndefinedOrZero(res)) dispatch(getSellerProfile());
  // }, []);

  const isGeneralDetailsAdded =
    res && Boolean(res.store_name && res.description);
  const isBrandingAdded = res && Boolean(res.logo && res.cover);
  const isDeclarationAdded =
    res && Boolean(res.declaration_date && res.authorized_person);
  const isAddressAdded = res && Boolean(res.address);
  const isDocumentsAdded =
    res &&
    Boolean(res.trade_license) &&
    Boolean(res.passport) &&
    Boolean(res.uae_id_front) &&
    Boolean(res.uae_id_back) &&
    Boolean(res.trade_license_no);
  const isBankAccountAdded = res && Boolean(res.bank_details);
  console.log(res && Boolean(res.address));
  console.log({ res });

  return (
    <>
      <TopBar title={t("store_management")} />
      {!loading && res && (
        <Box sx={{ marginTop: "65px", width: "100%", padding: "10px 20px" }}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <StoreIcon sx={{ marginRight: "10px" }} />
                  {t("general_details")}
                </Box>
              }
              details={<GeneralDetails />}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BrandingWatermarkIcon
                    size="medium"
                    sx={{ marginRight: "10px" }}
                  />
                  {t("branding")}
                </Box>
              }
              details={<Branding />}
              disabled={!isGeneralDetailsAdded}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <VerifiedUserIcon
                    size="medium"
                    sx={{ marginRight: "10px" }}
                  />
                  {t("declaration")}
                </Box>
              }
              details={<Declaration />}
              disabled={!isBrandingAdded}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LocationIcon size="medium" sx={{ marginRight: "10px" }} />
                  {t("business_address")}
                </Box>
              }
              details={<Address />}
              disabled={!isDeclarationAdded}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DocumentIcon size="medium" sx={{ marginRight: "10px" }} />
                  {t("documents")}
                </Box>
              }
              details={<Documents />}
              disabled={!isAddressAdded}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CreditCardIcon size="medium" sx={{ marginRight: "10px" }} />
                  {t("bank_account_details")}
                </Box>
              }
              details={<BankDetails />}
              disabled={!isDocumentsAdded}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <SAccordion
              summary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FileCopyIcon size="medium" sx={{ marginRight: "10px" }} />
                  {t("seller_agreement")}
                </Box>
              }
              details={<SellerAgreement />}
              disabled={!isBankAccountAdded}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Store;
