import { put, takeLatest } from 'redux-saga/effects';
import {  API_RATING } from 'appRedux/apiType';
import { actionType } from 'appRedux/actionType';
import apiCall from 'appRedux/apiCall';

// ----- Login Watcher -----
export function* watchGetRatingSaga() {
  yield takeLatest(actionType.GET_RATING_METHOD, getRatingSaga)
}

// ----- Saga -----
function* getRatingSaga(action) {
  try {
    let url = {
      type: API_RATING,
      payload: action.payload,
      apiType: 'GET'
    }
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_RATING_SUCCESS, payload: res })
  }
  catch (error) {
    yield put({ type: actionType.GET_RATING_FAILURE, payload: error })
  }
}

