import { isPlainObject } from "@mui/utils";
import axios from "axios";
import Cookies from "js-cookie";

export default async (method) => {
  return new Promise((resolve, reject) => {
    callApi(method, resolve, reject);
  });
};

export async function callApi(method, resolve, reject) {
  let headers = {
    "Content-Type": "multipart/form-data",
  };

  if (Cookies.get("token")) {
    let token = Cookies.get("token");
    headers.Authorization = `token ${token}`;
  }

  var formData;

  let axiosData = {
    method: method.apiType,
    headers: headers,
    url: method.type,
  };

  if (method.apiType === "GET") {
    if (Array.isArray(method.payload)) {
      const query = method.payload.map((x, index) => `${x.key}=${x.value}&`);
      axiosData.url = `${axiosData.url}?${query}`;
    } else if (typeof method.payload === "object" && method.payload.id) {
      axiosData.url = `${axiosData.url}${method.payload.id}/`;
    }
  } else if (method.apiType === "DELETE") {
    axiosData.url = `${axiosData.url}${method.payload.id}/`;
  } else if (method.apiType === "PATCH") {
    if (method.payload.id) {
      axiosData.url = `${axiosData.url}${method.payload.id}/`;
      delete method.payload.id;
    }
    formData = getFormData(method.payload);
    axiosData.data = formData;
  } else if (method.apiType === "POST") {
    formData = getFormData(method.payload);
    axiosData.data = formData;
  }

  try {
    let response = await axios(axiosData);

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      let res = {
        status: response.status,
        success: true,
        data: response.data,
      };
      resolve(res);
    } else if (response.status === 401) {
      // DO WHATEVER NEEDED FOR UNAUTHORISED USER (LOGOUT USER FROM HERE)
    } else if (response.status === 400) {
      let res = {
        status: response.status,
        success: false,
        data: response.data,
      };
      reject(res);
    } else {
      let res = {
        status: response.status,
        success: false,
        data: response.data,
      };
      reject(res);
    }
  } catch (err) {
    let response = err.response;
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      let res = {
        status: response.status,
        success: true,
        data: response.data,
      };
      resolve(res);
    } else if (response.status === 401) {
      // DO WHATEVER NEEDED FOR UNAUTHORISED USER (LOGOUT USER FROM HERE)
      let res = {
        status: response.status,
        success: false,
        data: response.data,
      };
      reject(res);
    } else if (response.status === 400) {
      let res = {
        status: response.status,
        success: false,
        data: response.data,
      };
      reject(res);
    } else {
      let res = {
        status: response.status,
        success: false,
        data: response.data,
      };
      reject(res);
    }
    //SHOW "SOMETHING WENT WRONG" MESSAGE HERE
  }
}

function getFormData(data) {
  let formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value) === true) {
      for (var i = 0; i < value.length; i++) {
        formData.append(`${key}`, value[i]);
      }
    } else {
      formData.append(`${key}`, value);
    }
  }
  return formData;
}

// function getFormData(data) {
//   let formData = new FormData();
//   buildFormData(data, '', formData)
//   return formData;
// }

// function buildFormData(data, key, form)
// {
//   for(var item in data)
//   {
//     if(Array.isArray(data[item]) )
//     {
//       for(var idx in data[item])
//         buildFormData(data[item][idx], key === '' ? item : `${key}[${item}]`, form)
//     }
//     else form.append(key === '' ? item : `${key}[${item}]`, data[item])
//   }
// }
