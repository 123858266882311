import { put, takeLatest } from "redux-saga/effects";
import { API_ORDER, API_DESC_ORDER, API_ORDER_STATUS } from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";

// ----- Order Watcher -----
export function* watchGetOrderSaga() {
  yield takeLatest(actionType.GET_ORDER_METHOD, getOrderSaga);
}

export function* watchGetOrderDetailSaga() {
  yield takeLatest(actionType.GET_ORDER_DETAIL_METHOD, getOrderDetailSaga);
}

export function* watchUpdateOrderSaga() {
  yield takeLatest(actionType.UPDATE_ORDER_METHOD, updateOrderSaga);
}

export function* watchUpdateOrderStatusHistorySaga() {
  yield takeLatest(
    actionType.UPDATE_ORDER_STATUS_HISTORY_METHOD,
    updateOrderStatusHistorySaga
  );
}

// ----- Saga -----
function* getOrderSaga(action) {
  try {
    let url = {
      type: API_DESC_ORDER,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_ORDER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_ORDER_FAILURE, payload: error });
  }
}

function* getOrderDetailSaga(action) {
  try {
    let url = {
      type: API_DESC_ORDER,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_ORDER_DETAIL_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_ORDER_DETAIL_FAILURE, payload: error });
  }
}

function* updateOrderSaga(action) {
  try {
    let url = {
      type: API_ORDER,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_ORDER_DETAIL_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_ORDER_DETAIL_FAILURE, payload: error });
  }
}

function* updateOrderStatusHistorySaga(action) {
  try {
    let url = {
      type: `${API_ORDER}${action.payload.id}/update-status/`,
      payload: action.payload,
      apiType: "PATCH",
    };
    delete action.payload.id;
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_ORDER_DETAIL_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.GET_ORDER_DETAIL_FAILURE, payload: error });
  }
}
