import React, { useState } from "react";
import ExpandMorePng from "assets/icons/DownArrow.png";

import {
  FormControl,
  FormLabel,
  Tooltip,
  Select,
  FormHelperText,
  MenuItem,
  Box,
  Icon,
  Popover,
} from "@mui/material";

const SSelect = (props) => {
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    sx: {
      "& .MuiMenu-root": {
        borderRadius: "10px",
      },
      "& .MuiPaper-root": {
        marginTop: "5px",
      },
    },
  };

  const iconComponent = (props) => {
    return (
      <Icon className={props.className} fontSize="large">
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={ExpandMorePng}
          alt="not found"
        />
      </Icon>
    );
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            fontFamily: "Book45",
            fontSize: "1.1rem",
            border: "none",
          },
        },
        "& .MuiInputBase-root": {},
      }}
    >
      <FormLabel
        component="legend"
        sx={{
          color: "#000",
          fontFamily: "Roman55",
          fontSize: "1.2rem",
          display: "flex",
          alignItems: "center",
          mb: 1,
        }}
      >
        {props.label}
        {props.tooltip && (
          <Tooltip title={props.tip} placement="right">
            <Box
              component="span"
              sx={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "21px",
                width: "21px",
                padding: "2px",
                ml: 1,
                background: "#707070",
                borderRadius: "50%",
                color: "#fff",
              }}
            >
              ?
            </Box>
          </Tooltip>
        )}
      </FormLabel>

      <Select
        sx={{
          background: "#F3F3F3",
          borderRadius: "5px",
          "& .MuiSelect-select": {
            // paddingLeft: '45px',
          },
          "& .MuiMenuItem-root": {
            border: "2px solid red",
          },
          "& .MuiInputBase-root": {
            borderRadius: "10px !important",
          },
          ...props.sx,
        }}
        displayEmpty
        MenuProps={menuProps}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        IconComponent={iconComponent}
        size="small"
        renderValue={props.renderValue}
      >
        <MenuItem value="" disabled>
          {props.placeholder}
        </MenuItem>
        {props.customOptions
          ? props.customOptions
          : props.options &&
            props.options.map((item) => {
              return (
                <MenuItem value={item[props.valueKey]}>
                  <span>{item[props.displayKey]}</span>
                </MenuItem>
              );
            })}
      </Select>
      <FormHelperText sx={{ color: "#F0142F", fontFamily: "Roman55" }}>
        {props.error && props.error}
      </FormHelperText>
    </FormControl>
  );
};

export default SSelect;
