// const BASE_URL = "http://localhost:8000/api";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_REGISTER = `${BASE_URL}/v1/store/sign-up/`;

export const API_LOGIN = `${BASE_URL}/v1/store/sign-in/`;

export const API_DESC_PRODUCT = `${BASE_URL}/v1/base/product/?order_by=-id`;

export const API_PRODUCT = `${BASE_URL}/v1/base/product/`;

export const API_PRODUCT_V = `${BASE_URL}/v1/product/`;

export const API_PRODUCT_VAR_IMG = `${BASE_URL}/v1/products/image/`;

export const API_IMPORT_PRODUCT = `${BASE_URL}/v1/base/product/import/`;

export const API_DESC_ORDER = `${BASE_URL}/v1/sellerorder/`;

export const API_ORDER = `${BASE_URL}/v1/order/`;

export const API_ORDER_STATUS = `${BASE_URL}/v1/order/update-status/`;

export const API_STORE_PROFILE = `${BASE_URL}/v1/store/profile/`;

export const API_STORE = `${BASE_URL}/v1/store/`;

export const API_MAGAZINE = `${BASE_URL}/v1/stores/magazines/`;

export const API_MAGAZINE_CATEGORY = `${BASE_URL}/v1/magazine/categories/`;

export const API_CATEGORY_PRIM = `${BASE_URL}/v1/category/?primary=true`;

export const API_CATEGORY = `${BASE_URL}/v1/category/`;

export const API_CATEGORY_ALL = `${BASE_URL}/v1/category/get-all-categories/`;

export const API_MATERIAL = `${BASE_URL}/v1/materials/`;

export const API_COLOR = `${BASE_URL}/v1/colors/`;

export const API_CHANGE_PASS = `${BASE_URL}/v1/users/password-change/`;

export const API_SEND_OTP = `${BASE_URL}/v1/users/send-otp/`;

export const API_RESEND_OTP = `${BASE_URL}/v1/users/resend-otp/`;

export const API_OTP_VERIFY = `${BASE_URL}/v1/users/otp-verify/`;

export const API_RATING = `${BASE_URL}/v1/ratings/`;

export const API_CITY_CHOICE = `${BASE_URL}/v1/users/city-choices/`;

export const API_GET_SELLER_AGREEMENT = `${BASE_URL}/v1/store/get-nda/`;

export const API_ACCEPT_SELLER_AGREEMENT = `${BASE_URL}/v1/store/sign-nda/`;

export const API_DASHBARD = `${BASE_URL}/v1/store/dashboard/`;

export const API_EARNING = `${BASE_URL}/v1/store/earnings/`;

export const API_REQUEST_WITHDRAW = `${BASE_URL}/v1/store/request-withdraw/`;
