import { API_STORE, API_STORE_PROFILE } from "appRedux/apiType";
import { actionType } from "appRedux/actionType";
import apiCall from "appRedux/apiCall";
import { takeLatest, put, takeEvery } from "redux-saga/effects";
import Toaster from "common/Toaster";
import Cookies from "js-cookie";

// ----- Get Product Watcher -----
export function* watchSellerProfileSaga() {
  yield takeLatest(actionType.GET_PROFILE_METHOD, getSellerProfileSaga);
}

// ----- Get Product Watcher -----
export function* watchUpdateSellerProfileSaga() {
  yield takeEvery(actionType.UPDATE_PROFILE_METHOD, updateSellerProfileSaga);
}

// ----- Saga -----
function* getSellerProfileSaga(action) {
  try {
    let url = {
      type: API_STORE_PROFILE,
      payload: action.payload,
      apiType: "GET",
    };
    let token = Cookies.get("token");
    if (!token) return;
    const res = yield apiCall(url);
    yield put({ type: actionType.GET_PROFILE_SUCCESS, payload: res });
  } catch (error) {
    console.log(error);
    yield put({ type: actionType.GET_PROFILE_FAILURE, payload: error });
  }
}

// ----- Saga -----
function* updateSellerProfileSaga(action) {
  try {
    let url = {
      type: API_STORE,
      payload: action.payload,
      apiType: "PATCH",
    };
    const res = yield apiCall(url);
    if (res.success) {
      Toaster({ type: "success", message: "Profile updated successfully" });
    }

    yield put({ type: actionType.UPDATE_PROFILE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionType.UPDATE_PROFILE_FAILURE, payload: error });
  }
}
