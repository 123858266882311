import React, { useState } from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  FormLabel,
  Box,
  Tooltip,
} from "@mui/material";
import SummernoteLite from "react-summernote-lite";
import "react-summernote-lite/dist/esm/dist/summernote-lite.min.css";
import colors from "utils/colors";

const SInput = (props) => {
  React.useEffect(() => {
    if (!props.editor) return;

    document
      .querySelector(".note-editable")
      .setAttribute("contenteditable", !props.disabled);
  }, []);
  return (
    <FormControl sx={{ width: "100%" }}>
      <FormLabel
        component="legend"
        sx={{
          color: colors.primary,
          fontFamily: "Roman55",
          fontSize: "1.2rem",
          display: "flex",
          alignItems: "center",
          mb: 1,
        }}
      >
        {props.label}
        {props.tooltip && (
          <Tooltip title={props.tip} placement="right">
            <Box
              component="span"
              sx={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "21px",
                width: "21px",
                padding: "2px",
                ml: 1,
                background: "#707070",
                borderRadius: "50%",
                color: "#fff",
              }}
            >
              ?
            </Box>
          </Tooltip>
        )}
      </FormLabel>

      {props.editor ? (
        <SummernoteLite
          defaultCodeValue={props.value || ""}
          placeholder={"Write something here..."}
          tabsize={2}
          height={350 || "50vh"}
          dialogsInBody={true}
          blockquoteBreakingLevel={0}
          callbacks={{
            onChange: (contents, $editable) => {
              props?.onChange(contents);
            },
          }}
          toolbar={[
            ["style", ["style"]],
            [
              "font",
              [
                "bold",
                "underline",
                "clear",
                "strikethrough",
                "superscript",
                "subscript",
              ],
            ],
            ["fontsize", ["fontsize"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["view", ["undo", "redo", "codeview"]],
          ]}
        />
      ) : (
        <TextField
          sx={{
            // background: "#F3F3F3",
            // borderRadius: "5px",
            fontFamily: "Book45",
            fontSize: "1.1rem",
            px: 1,

            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#ccc",
            transition: "border-color 0.2s",
            "&:has(.MuiInputBase-input:focus)": {
              borderColor: colors.primary,
              // background: colors.primary,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                // background: '#F3F3F3',
                border: "none",
              },
            },
            "& .MuiOutlinedInput-root:hover": {
              "& fieldset": {
                border: "none",
              },
            },
            "& .MuiInputBase-root": {
              borderRadius: "10px",
            },
            "& .MuiInputBase-input": {
              paddingLeft: "7px",
            },
            "& ::placeholder": {
              fontFamily: "Roman55",
            },
            ...props.sx,
          }}
          value={props.value}
          type={props.type ? props.type : "text"}
          onChange={props.onChange}
          placeholder={props.placeholder}
          InputProps={props.InputProps}
          multiline={props.multiline}
          maxRows={props.maxRows}
          rows={props.rows}
          disabled={props.disabled ? props.disabled : false}
          size="small"
        />
      )}
      <FormHelperText sx={{ color: "#F0142F", fontFamily: "Roman55" }}>
        {props.error && props.error}
      </FormHelperText>
    </FormControl>
  );
};

export default SInput;
