import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'assets/logo-gold.png';

//Mui Import 
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Link as Anchor,
  Typography,
} from '@mui/material';

import SpaciumLink from './SpaciumLink';
import { shallowEqual, useSelector } from 'react-redux';

const drawerWidth = 250;

const Sidebar = ({ handleTermsOpen, handlePolicyOpen }) => {
  const { isDrawerOpen } = useSelector(
    (state) => ({
      isDrawerOpen: state.drawerReducer.isDrawerOpen
    }),
    shallowEqual
  );

  return (
    <Drawer
      sx={{
        position: 'relative',
        width: isDrawerOpen ? 0 : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isDrawerOpen ? 0 : drawerWidth,
          bgcolor: 'primary.main',
          boxShadow: '5px 0 6px rgba(0,97,86,.25)',
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      // anchor="left"
      open={false}
    >
      <Toolbar >
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '150px' }}>
          <Anchor target="_blank" href='https://spacium.ae/'>
            <img
              src={Logo}
              alt="Logo not found"
              style={{ width: '100px' }}
            />
          </Anchor>
        </Box>
      </Toolbar>
      <SpaciumLink />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: 'auto',
        marginLeft: '60px',
        padding: '50px 0px',

      }} >
        <NavLink style={{ textDecoration: "none", fontFamily: 'Book45', color: 'rgba(255,255,255, .75)' }} to="/faqs">FAQs</NavLink>
        <Typography onClick={handlePolicyOpen} sx={{ cursor: 'pointer', textAlign: 'left', fontFamily: 'Book45', color: 'rgba(255,255,255, .75)', mb: 1, mt: 1 }}>Privacy Policy</Typography>
        <Typography onClick={handleTermsOpen} sx={{ cursor: 'pointer', textAlign: 'left', fontFamily: 'Book45', color: 'rgba(255,255,255, .75)' }}>Terms &amp; Conditions</Typography>
      </Box>
    </Drawer >
  )
}

export default Sidebar