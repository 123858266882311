import { getSellerProfile } from "appRedux/actions/storeAction";
import CustomLoading from "common/CustomLoading/CustomLoading";
import Loading from "common/loader";
import CustomizeIt from "component/customize-it";
import Store from "component/store";
import Layout from "layout";
import React, { Suspense, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoutes from "routes/protectedRoutes";
import ScreenRoutes from "routes/ScreenRoutes";
import { isNullEmptyUndefinedOrZero } from "utils";

//Routes Import
const Login = React.lazy(() => import("./component/auth/Login"));
const Register = React.lazy(() => import("./component/auth/Register"));
const EmailVerified = React.lazy(() =>
  import("./component/auth/EmailVerified")
);
const RequestResetPassword = React.lazy(() =>
  import("./component/auth/RequestResetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./component/auth/ResetPassword")
);
const OtpVerification = React.lazy(() =>
  import("./component/auth/OtpVerification")
);

const App = () => {
  const dispatch = useDispatch();
  const { profileLoading, profileError, profileRes } = useSelector(
    (state) => ({
      profileLoading: state.sellerProfile.loading,
      profileError: state.sellerProfile.error,
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );
  const isSellerRegistered =
    profileRes?.seller_agreement_date &&
    profileRes?.manager?.user_approved &&
    profileRes?.manager?.is_phone_verified;

  const isPhoneVerified =
    (profileLoading !== false && !profileError) ||
    profileRes?.manager?.is_phone_verified == 1;
  console.log({ isPhoneVerified, profileLoading });

  useEffect(() => {
    if (isNullEmptyUndefinedOrZero(profileRes)) dispatch(getSellerProfile());
  }, []);

  // if (profileLoading && !profileError) {
  //   return <Loading />;
  // }

  return (
    <Suspense fallback={<CustomLoading />}>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/email-verified" element={<EmailVerified />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/request-reset-password"
            element={<RequestResetPassword />}
          />
          <Route
            path={"customize-it"}
            name={"CustomizeIt"}
            element={<CustomizeIt />}
          />

          <Route
            exact
            path="/mobile-verification"
            element={<OtpVerification />}
          />

          <Route path="/" name="Home" element={<ProtectedRoutes />}>
            <Route path="/" element={<Navigate replace to="/dashboard" />} />

            {isSellerRegistered &&
              ScreenRoutes.map((route, idx) => {
                return (
                  <Route
                    key={`Layout_${idx}`}
                    path={route.path}
                    name={route.name}
                    element={route.element}
                  />
                );
              })}
            <Route path={"store"} name={"Store"} element={<Store />} />
            {profileLoading && !profileError && (
              <Route
                path="*"
                element={
                  <Layout>
                    <Loading />
                  </Layout>
                }
              />
            )}
            {profileError && (
              <Route path="*" element={<Navigate to={"/store"} replace />} />
            )}
            {!isPhoneVerified ? (
              <Route
                path="*"
                element={<Navigate to="/mobile-verification" replace />}
              />
            ) : (
              <Route path="*" element={<Navigate to={"/store"} replace />} />
            )}
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
