import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SInput from "common/material/panel/SInput";
import SButton from "common/material/panel/SButton";
import BankDetailsImg from "assets/BankDetails.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { API_STORE } from "appRedux/apiType";
import apiCallV2 from "appRedux/apiCallV2";
import { getSellerProfile } from "appRedux/actions/storeAction";
import { getErrorMessage } from "utils";

const BankDetails = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();
  const [beneficiary, setBeneficiary] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [iban, setIban] = useState("");

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const disabled = !!profileRes?.seller_agreement_date;

  const handleSetData = () => {
    setBeneficiary(profileRes.bank_details.beneficiary_name);
    setBankName(profileRes.bank_details.bank_name);
    setBranchName(profileRes.bank_details.branch_name);
    setAccountNumber(profileRes.bank_details.account_number);
    setSwiftCode(profileRes.bank_details.swift_code);
    setIban(profileRes.bank_details.iban);
  };

  const save = () => {
    const apiPayload = {
      beneficiary_name: beneficiary,
      bank_name: bankName,
      branch_name: branchName,
      account_number: accountNumber,
      swift_code: swiftCode,
      iban,
    };

    const apiData = {
      type: `${API_STORE}add-bank-account/`,
      apiType: "POST",
      payload: apiPayload,
    };
    if (profileRes.bank_details && profileRes.bank_details.id) {
      apiData.type = `${API_STORE}${profileRes.bank_details.id}/bank-account/`;
      apiData.apiType = "PATCH";
    }
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  useEffect(() => {
    if (profileRes && profileRes.bank_details) {
      handleSetData();
    }
  }, [profileRes]);

  return (
    <Box>
      <Grid container sx={{ mb: 2 }}>
        <Grid item sx={{ width: "48%" }}>
          <Grid sx={{ mb: 2, pr: 3, display: "flex" }}>
            <Box sx={{ width: "30px" }}>
              <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                1.
              </Typography>
            </Box>
            <SInput
              label={t("beneficiary_name")}
              value={beneficiary}
              disabled={disabled}
              onChange={(e) => setBeneficiary(e.target.value)}
            />
          </Grid>

          <Grid sx={{ mb: 2, pr: 3, display: "flex" }}>
            <Box sx={{ width: "30px" }}>
              <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                2.
              </Typography>
            </Box>
            <SInput
              label={t("bank_name")}
              value={bankName}
              disabled={disabled}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Grid>

          <Grid sx={{ mb: 2, pr: 3, display: "flex" }}>
            <Box sx={{ width: "30px" }}>
              <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                3.
              </Typography>
            </Box>
            <SInput
              label={t("branch_name")}
              value={branchName}
              disabled={disabled}
              onChange={(e) => setBranchName(e.target.value)}
            />
          </Grid>

          <Grid sx={{ mb: 2, pr: 3, display: "flex" }}>
            <Grid sm={6} sx={{ pr: 1, display: "flex" }}>
              <Box sx={{ width: "30px" }}>
                <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                  4.
                </Typography>
              </Box>
              <SInput
                label={t("account_number")}
                value={accountNumber}
                disabled={disabled}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </Grid>

            <Grid sm={6} sx={{ pl: 1, display: "flex" }}>
              <Box sx={{ width: "30px" }}>
                <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                  5.
                </Typography>
              </Box>
              <SInput
                label={t("swift_code")}
                value={swiftCode}
                disabled={disabled}
                onChange={(e) => setSwiftCode(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid sm={12} sx={{ pl: 1, display: "flex" }}>
            <Box sx={{ width: "30px" }}>
              <Typography sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}>
                6.
              </Typography>
            </Box>
            <SInput
              label={t("iban")}
              value={iban}
              disabled={disabled}
              onChange={(e) => setIban(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid sx={{ width: "50%", display: "flex" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={BankDetailsImg} alt="Not Found" />
          </Box>
        </Grid>
      </Grid>
      <Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SButton
            mySx={{ borderRadius: "25px", width: "140px" }}
            disabled={disabled}
          >
            {t("cancel")}
          </SButton>
          <SButton
            variant="contained"
            mySx={{ borderRadius: "25px", width: "140px", ml: 4 }}
            disabled={disabled}
            onClick={(e) => save()}
          >
            {t("save")}
          </SButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default BankDetails;
