import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { toggleDrawer } from "appRedux/actions/drawerAction";
import { LogoutIcon } from "common/icons";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeUrlToProductionSite, logoutUser } from "utils";
import useWindowDimensions from "utils/useWindowDimensions";

const drawerWidth = 250;

const TopBar = ({ title }) => {
  // const id = useId();
  // console.log(id);
  // console.log(width);

  const { width } = useWindowDimensions();
  // console.log(width);

  const dispatch = useDispatch();
  const sideBarHandler = () => {
    dispatch(toggleDrawer({ open: !isDrawerOpen }));
  };
  const { profileRes, isDrawerOpen } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
      isDrawerOpen: state.drawerReducer.isDrawerOpen,
    }),
    shallowEqual
  );

  if (width < 620 && width > 600 && !isDrawerOpen) {
    dispatch(toggleDrawer({ open: !isDrawerOpen }));
  }

  const navigate = useNavigate();

  const logout = () => {
    logoutUser(navigate);
  };

  // useEffect(() => {
  //   if (width < 600) {
  //     console.log("check", width);
  //     dispatch(toggleDrawer({ open: true }));
  //   }
  // }, [width]);

  return (
    <AppBar
      sx={{
        background: "#fff",
        boxShadow: "0 4px 6px rgba(0,0,0,.03)",
        width: `calc(100% - ${isDrawerOpen ? 0 : drawerWidth}px)`,
        overflow: "none",
        // ml: `${drawerWidth}px`,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="helo"
          style={{ cursor: "pointer" }}
          onClick={sideBarHandler}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#202020"
            className="w-6 h-6"
            style={{ width: "2rem" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
        <Box>
          <Typography
            sx={{
              fontFamily: "Heavy85",
              fontSize: "1.2rem",
              color: "#000",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontFamily: "Heavy85",
                  fontSize: "1.1rem",
                  color: "#000",
                }}
              >
                {Cookies.get("name")}
              </Typography>
              {profileRes?.store_name && (
                <Typography
                  sx={{
                    fontFamily: "Book45",
                    fontSize: ".70rem",
                    color: "#000",
                    opacity: 0.5,
                  }}
                >
                  {profileRes?.store_name}
                </Typography>
              )}
            </Box>
            {profileRes?.logo && (
              <Box
                sx={{
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "52px",
                  width: "55px",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={changeUrlToProductionSite(profileRes.logo)}
                  alt="not found"
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "25%", objectFit: "cover" }}
                />
              </Box>
            )}
          </Box>
          <Button
            sx={{
              fontFamily: "Heavy85",
              textTransform: "none",
              boxShadow: "none",
              color: "#F0142F",
              marginLeft: "10px",
            }}
            onClick={logout}
          >
            Log Out &nbsp; <LogoutIcon size="small" />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
