import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress
        color="primary"
        fontSize={props.fontSize || "medium"}
        sx={{ ...props.style }}
        size={props.size || 50}
      />
    </Box>
  );
};

export default Loading;
