import React from 'react'
import { Modal } from '@mui/material'

const SModal = (props) => {

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
    // aria-labelledby={props.titleId}
    // aria-describedby={props.descriptionId}
    >
      {props.children}
    </Modal>
  )
}

export default SModal;