import { actionType } from "appRedux/actionType";

const INITIAL_STATE = {
  lading: false,
  error: "",
  res: "",
};

export default function getProductDetail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionType.GET_PRODUCT_DETAIL_METHOD:
      return { ...state, loading: true, error: "" };
    case actionType.GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        res: action.payload.data,
      };
    case actionType.GET_PRODUCT_DETAIL_FAILURE:
      let terror = action.payload;
      return {
        ...state,
        loading: false,
        error: terror,
      };
    case actionType.GET_PRODUCT_DETAIL_CLEAR:
      return {
        error: "",
        loading: false,
        res: "",
      };
    default:
      return state;
  }
}
