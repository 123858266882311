import { Close } from "@mui/icons-material";
import { Box, FormControl, Grid, IconButton, Typography } from "@mui/material";
import { getSellerProfile } from "appRedux/actions/storeAction";
import apiCallV2 from "appRedux/apiCallV2";
import { API_STORE } from "appRedux/apiType";
import DropImg from "assets/drop-img-icon.png";
import SButton from "common/material/panel/SButton";
import Toaster from "common/Toaster";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeUrlToProductionSite, getErrorMessage } from "utils";

const Branding = () => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch();
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");

  const { profileRes } = useSelector(
    (state) => ({
      profileRes: state.sellerProfile.res,
    }),
    shallowEqual
  );

  const disabled = !!profileRes?.seller_agreement_date;

  const save = () => {
    const apiPayload = {
      logo: logo,
      cover: cover,
    };
    if (logo == profileRes.logo) delete apiPayload.logo;
    if (cover == profileRes.cover) delete apiPayload.cover;

    if (!apiPayload.logo && !apiPayload.cover) {
      return;
    }

    const apiData = {
      type: `${API_STORE}${profileRes.id}/manage-store/`,
      apiType: "PATCH",
      payload: apiPayload,
    };
    apiCallV2(apiData)
      .then((res) => dispatch(getSellerProfile()))
      .catch((err) => getErrorMessage(err))
      .finally(() => {});
  };

  const handleSetData = () => {
    setLogo(changeUrlToProductionSite(profileRes.logo));
    setCover(changeUrlToProductionSite(profileRes.cover));
  };

  useEffect(() => {
    if (profileRes) {
      handleSetData();
    }
  }, [profileRes]);

  const RenderUploaderCard = ({ count, title, image, setImage }) => {
    return (
      <Box
        md={4}
        sx={{
          mb: 2,
          mr: 2,
          pl: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Roman55",
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box
              component="span"
              sx={{ fontFamily: "Black95", fontSize: "1.2rem" }}
            >
              {count}.{" "}
            </Box>{" "}
            &nbsp; {title}
          </Typography>
        </Box>
        {image ? (
          <Box
            sx={{
              position: "relative",
              border: "2px solid #F3F3F3",
              borderRadius: "10px",
              width: "100%",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              alt="not found"
            />
            <IconButton
              sx={{
                width: "25px",
                height: "25px",
                background: "rgba(0,0,0,.2)",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <Close
                fontSize="small"
                sx={{ color: "#fff" }}
                onClick={(e) => setImage("")}
              />
            </IconButton>
          </Box>
        ) : (
          <FormControl sx={{ width: "100%" }}>
            <FileUploader
              name="file"
              types={["jpg", "png", "jpeg"]}
              maxSize={0.4}
              onSizeError={(file) => {
                Toaster({
                  type: "error",
                  message: "File should not be more than 400 KB",
                });
              }}
              onDrop={(file) => {
                setImage(file);
              }}
              onSelect={(file) => {
                setImage(file);
              }}
              children={
                <Box
                  sx={{
                    border: "2px dashed #F3F3F3",
                    position: "relative",
                    borderRadius: "10px",
                    width: "100%",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={DropImg} alt="not found" />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "Book45",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    Drag your image of MAX 400KB here,
                    <br />
                    or{" "}
                    <Box
                      component="span"
                      sx={{
                        fontFamily: "Heavy85",
                        color: "#006156",
                        textDecoration: "underline",
                      }}
                    >
                      Browse
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </FormControl>
        )}
      </Box>
    );
  };

  return (
    <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ width: "100%", display: "flex" }}>
        <RenderUploaderCard
          count={1}
          title={t("logo")}
          image={logo}
          setImage={(image) => !disabled && setLogo(image)}
        />
        <RenderUploaderCard
          count={2}
          title={t("cover_image")}
          image={cover}
          setImage={(image) => !disabled && setCover(image)}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <SButton
          variant="contained"
          mySx={{ borderRadius: "25px", width: "140px" }}
          disabled={disabled}
          onClick={(e) => save()}
        >
          {t("upload_image")}
        </SButton>
      </Box>
    </Grid>
  );
};

export default Branding;
